import {useState, useEffect } from "react";
import useProductData from "app/carga-documentos/utilities/useProductData";
import { useParams } from 'react-router-dom';
import { ConfigVariables } from "adapters/ports/config-variables";
import HttpClientService from "http/httpClientService";

export default function useLegalChecks() {
    const [legalDocuments, setLegalDocuments] = useState(false);
    const [legalOrigins, setLegalOrigins] = useState(false);
    const [legalFinmaqgo, setLegalFinmaqgo] = useState(false);

    const { productIndex } = useParams();

    const { sortedDataByCreatedAt, person_type, identity_type, identity_number } = useProductData();

    const isAllLegalChecked = legalDocuments && legalOrigins && legalFinmaqgo;

    useEffect(()=>{
        const selectedProductData = sortedDataByCreatedAt.length >= 1 && Number(productIndex) >= 0 ? sortedDataByCreatedAt[Number(productIndex)] : {};
        const product_uuid = (selectedProductData as unknown as { product_uuid: string }).product_uuid;
        const apiBaseUrl = ConfigVariables.URL_BASE_API;
        const endpoint = `${apiBaseUrl}client-profile/client/${person_type}_${identity_type}_${identity_number}/approvals/${product_uuid}`;

        if(person_type && product_uuid){
            (async () => {
                try {
                    const response = await HttpClientService.get<string>(endpoint);
                    const stringifytext = JSON.stringify(response);
                    const parsetext = JSON.parse(stringifytext);

                    const data = parsetext?.authorizations?.documents_approvals;

                    setLegalDocuments(data?.approves_data_handling_policy_documents);
                    setLegalOrigins(data?.approves_funds_origins);
                    setLegalFinmaqgo(data?.approves_terms_finmaq_go);

                } catch (error){
                    console.log(error);
                }
            })();
        }
    },[identity_number, identity_type, person_type, productIndex, sortedDataByCreatedAt])

    return { isAllLegalChecked, legalDocuments, legalOrigins, legalFinmaqgo }
}
