import { userPool } from "app/login/adapters/user-pool-cognito";
import { useState, useEffect, useMemo } from "react";
import { ConfigVariables } from "adapters/ports/config-variables";
import HttpClientService from "http/httpClientService";

const parseCustomDate = (dateString:string) => {
    const [datePart, timePart] = dateString.split(', ');
    const [day, month, year] = datePart.split('/');
    const [hours, minutes, seconds] = timePart.split(':');
    
    return new Date(Number(year), Number(month) - 1, Number(day), Number(hours), Number(minutes), Number(seconds)).valueOf();
}

const useProductData = ()=>{
    const [person_type, setPersonType] = useState<string | null>(null);
    const [identity_type, setIdentityType] = useState<string | null>(null);
    const [identity_number, setIdentityNumber] = useState<string | null>(null);

    const [productData, setProductData] = useState("");
    const [contactData, setContactData] = useState("");
    const [email, setEmail] = useState("");

    const sortedDataByCreatedAt = useMemo(()=>{
        const sortedData = Object.entries(productData).sort((a, b)=>{
            const valueA = parseCustomDate(a[1]["created_at" as keyof typeof a[1]].toString());
            const valueB = parseCustomDate(b[1]["created_at" as keyof typeof b[1]].toString());
            return valueB - valueA;
        })

        return sortedData.map((value)=>{
            return value[1]
        })
    },[productData])

    useEffect(() => {
        const cognitoUser = userPool.getCurrentUser();
        
        if (cognitoUser && (!person_type || !identity_type || !identity_number)) {
            cognitoUser.getSession((err: any, session: any) => {
                if (err) {
                    console.error("Error getting session:", err);
                    window.location.href = "/#revoked-token";
                    return;
                }

                cognitoUser.getUserAttributes((err: any, attributes: any) => {
                    if (err) {
                        console.error("Error getting user attributes:", err);
                        window.location.href = "/#revoked-token";
                        return;
                    }

                    const userID = attributes.find((attr: any) => attr.Name === 'custom:userID').Value.split("/");
                    const email = attributes.find((attr: any) => attr.Name === 'email').Value;
                    const [person_type, identity_type, identity_number] = userID;

                    setPersonType(person_type);
                    setIdentityType(identity_type);
                    setIdentityNumber(identity_number);
                    setEmail(email);
                });
            });
        }
        
        if(productData === "" && person_type && identity_type && identity_number){
            (async () => {
                try {
                    const apiBaseUrl = ConfigVariables.URL_BASE_API;

                    const getUserEndpoint = `${apiBaseUrl}client-profile/client/${person_type}_${identity_type}_${identity_number}`;
                    const response = await HttpClientService.get<string>(getUserEndpoint);
                    const stringifytext = JSON.stringify(response);
                    const parsetext = JSON.parse(stringifytext);
                    
                    setProductData(parsetext.client.products);
                    setContactData(parsetext.client);

                } catch (error) {
                    console.error('Error al obtener la data del usuario:', error);
                    window.location.href = "/error-500";
                    throw error;
                }
            })(); 
        }   
    },[productData, person_type, identity_type, identity_number])

    return { contactData, person_type, identity_type, identity_number, email, sortedDataByCreatedAt }
}

export default useProductData;
