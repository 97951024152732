import { Routes, Route, Navigate } from "react-router-dom";

import RTLLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import "assets/css/Plugins.css";
import { useState, useEffect } from "react";
import Login from "app/login/interfaces/ui/login";
import PagePrincipal from "app/interfaces-prueba/ui/page-principal-client";
import PageAnalist from "app/interfaces-prueba/ui/page-principal-analist";
import BeneficiosSolicitudCredito from "app/on-board/interfaces/ui/beneficios-solicitud";
import CargaDocumentos from "app/on-loading/interfaces/ui/carga-documentos";
import ComenzarCargaDocumentos from "app/on-board/interfaces/ui/comenzar-carga";
import CargaDocumentoIdentificacion from "app/carga-documentos/documento-identificacion";
import DocumentosFiscales from "app/carga-documentos/documentos-fiscales";
import FormularioInfoMaquina from "app/formulario-info-maquina/interfaces/ui/formulario-info-maquina";
import FormularioSolicitud from "app/formulario-solicitud/interfaces/ui/formulario-solicitud";
import PreguntasFrecuentes from "app/preguntas-frecuentes/interfaces/ui/preguntas-frecuentes";
import ExtractosBancarios from "app/carga-documentos/extractos-bancarios";
import RecuperacionPassword from "app/login/interfaces/ui/recuperacion-password";
import DeclaracionOrigenFondos from "app/terminos-y-condiciones/declaracion-de-origen-de-fondos";
import Autorizaciones from "app/terminos-y-condiciones/autorizaciones";
import MisCreditos from "app/mis-creditos/interfaces/ui/mis-creditos"
import PageError404 from "app/error-pages/error-404";
import PageError500 from "app/error-pages/error-500";
import PageErrorConnectionTimedOut from "app/error-pages/error-connection-timed-out";
import TerminosYCondicionesUsoApp from "app/terminos-y-condiciones/terminos-y-condiciones";

const App = () => {
  const [themeApp, setThemeApp] = useState<any>({
    "--background-100": "#FFFFFF",
    "--background-900": "#070f2e",
    "--shadow-100": "rgba(112, 144, 176, 0.08)",
    "--color-50": "#E9E3FF",
    "--color-100": "#C0B8FE",
    "--color-200": "#A195FD",
    "--color-300": "#8171FC",
    "--color-400": "#7551FF",
    "--color-500": "#422AFB",
    "--color-600": "#3311DB",
    "--color-700": "#2111A5",
    "--color-800": "#190793",
    "--color-900": "#11047A",
  });
  const [mini, setMini] = useState(false);

  useEffect(() => {
    let color;
    for (color in themeApp) {
      document.documentElement.style.setProperty(color, themeApp[color]);
    }
    //eslint-disable-next-line

    if (process.env.NODE_ENV === "production") {
      const script = document.createElement("script");
      script.innerHTML = `
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:5216824,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
      document.head.appendChild(script);
    }

  }, [themeApp]);
    
  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route
        path="admin/*"
        element={
          <AdminLayout
            setMini={setMini}
            mini={mini}
            theme={themeApp}
            setTheme={setThemeApp}
          />
        }
      />
      <Route
        path="rtl/*"
        element={
          <RTLLayout
            setMini={setMini}
            mini={mini}
            theme={themeApp}
            setTheme={setThemeApp}
          />
        }
      />
      <Route path="/" element={<Login/>} />
      <Route path="/page-client" element={<PagePrincipal/>}/>
      <Route path="/page-analist" element={<PageAnalist/>}/>
      <Route path="/beneficios-credito" element={<BeneficiosSolicitudCredito/>}/>
      <Route path="/comenzar-carga" element={<ComenzarCargaDocumentos/>}/>
      <Route path="/recuperacion-password" element={<RecuperacionPassword/>}/>
      <Route path="/declaracion-de-fondos" element={<DeclaracionOrigenFondos/>}/>
      <Route path="/autorizaciones" element={<Autorizaciones/>}/>
      <Route path="*" element={<PageError404/>}/>
      <Route path="/error-500" element={<PageError500/>}/>
      <Route path="/error-connection-timed-out" element={<PageErrorConnectionTimedOut/>}/>
      <Route path="/mis-creditos" element={<MisCreditos/>}/>
      <Route path="/terminos-y-condiciones-uso-aplicacion" element={<TerminosYCondicionesUsoApp/>}/>

      <Route path="/preguntas-frecuentes/:productIndex" element={<PreguntasFrecuentes/>}/>
      <Route path="/carga-documentos/:productIndex" element={<CargaDocumentos/>}/>
      <Route path="/documento-identificacion/:productIndex" element={<CargaDocumentoIdentificacion/>}/>
      <Route path="/documentos-fiscales/:productIndex" element={<DocumentosFiscales/>}/>
      <Route path="/extractos-bancarios/:productIndex" element={<ExtractosBancarios/>}/>
      <Route path="/informacion-maquina/:productIndex" element={<FormularioInfoMaquina/>}/>
      <Route path="/formulario-solicitud/:productIndex" element={<FormularioSolicitud/>}/>

      <Route path="/preguntas-frecuentes" element={<PreguntasFrecuentes/>}/>
      <Route path="/carga-documentos" element={<Navigate to="/mis-creditos" replace />}/>
      <Route path="/documento-identificacion" element={<Navigate to="/mis-creditos" replace />}/>
      <Route path="/documentos-fiscales" element={<Navigate to="/mis-creditos" replace />}/>
      <Route path="/extractos-bancarios" element={<Navigate to="/mis-creditos" replace />}/>
      <Route path="/informacion-maquina" element={<Navigate to="/mis-creditos" replace />}/>
      <Route path="/formulario-solicitud" element={<Navigate to="/mis-creditos" replace />}/>
    </Routes>
  );
};

export default App;



