import ciudades from "./ciudades";
import paises from "./paises";

export interface FormFieldsProps {
    title: string;
    inputs: FieldsProps[];
}

export interface FieldsProps{
    fieldType?: 'text' | 'email' | 'number' | 'date' | 'phone' | 'radio' | 'select' | 'checkbox' | 'nested_select' | 'placeholder';
    section?: string;
    name?: string;
    subtitle?: string;
    tooltip?: string;
    maxLength?: number;
    styleClass?: string;
    previousLock?: boolean; 
    label?: string;
    group?: string;
    options?: OptionProps[];
}

export interface ObjectProps {
    [key: string]: any
}

export interface OptionProps {
    label: string;
    value: string;
}

const formFieldsNatural: FormFieldsProps[] = [
    {
        title: "Datos personales",
        inputs: [
            { subtitle: "Datos personales" },
            { section: "contact", name: "first_name", label: "Nombres", fieldType: "text", previousLock: true},
            { section: "contact", name: "first_lastname", label: "Apellidos", fieldType: "text", previousLock: true },
            { section: "contact", name: "identity_type", label: "Tipo de documento", fieldType: "text", previousLock: true },
            { section: "contact", name: "identity_number", label: "Número de documento",  fieldType: "text", previousLock: true },
            { section: "contact", name: "born_date", label: "Fecha de nacimiento", fieldType: "date" },
            { section: "contact", name: "cellphone_number", label: "Teléfono", fieldType: "phone" },
            { section: "contact", name: "nationality", label: "Nacionalidad", fieldType: "select", options: paises },
            { section: "products", name: "created_at", label: "Fecha de solicitud de credito",  fieldType: "date", previousLock: true },
            { section: "contact", name: "gender", label: "Género", fieldType: "radio", options: [
                { label: "Femenino",  value: "FEMENINO" },
                { label: "Masculino", value: "MASCULINO" },
                { label: "Otro", value: "OTRO" }
            ]},
            { section: "contact", name: "politically_exposed", label: "PEP (Persona pública o políticamente expuesta)", tooltip: "Personas que cumplen o a quienes se les han confiado funciones públicas", fieldType: "radio", options: [
                { label: "Si",  value: "true" },
                { label: "No", value: "false" }
            ]}
        ]
    },{
        title: "Datos de residencia y trabajo",
        inputs: [
            { subtitle: "Datos de residencia" },
            { section: "contact", group: "residence_info", name: "address", label: "Dirección", fieldType: "text", maxLength: 100 },
            { section: "contact", group: "residence_info", name: "department", fieldType: "nested_select" },
            { section: "contact", group: "residence_info", name: "city", fieldType: "placeholder" },
            { section: "contact", group: "residence_info", name: "neighborhood", label: "Barrio", fieldType: "text", maxLength: 100 },
            { subtitle: "Datos de trabajo" },
            { section: "contact", group:"work_info", name: "activity_start_date", label: "Tiempo inicio de actividad", fieldType: "date", tooltip: "Indique la fecha exacta en la que inició formalmente las operaciones de su negocio. Esto incluye la fecha en la que empezó a ofrecer productos o servicios, aunque aún no haya registrado su negocio oficialmente." },
            { section: "contact", group:"work_info", name: "months_experience", label: "Meses de experiencia en el sector", fieldType: "number", tooltip: "Ingrese el número total de meses que ha trabajado en el sector relacionado con su negocio. Si tiene experiencia previa en diferentes roles dentro del mismo sector, asegúrese de sumar todos los meses para obtener un total.", maxLength: 3},
            { section: "contact", group:"work_info", name: "address", label: "Dirección", fieldType: "text", maxLength: 100},
            { section: "contact", name: "email", label: "Correo electrónico", fieldType: "email", previousLock: true },
            { section: "contact", group:"work_info", name: "phone_number", label: "Telefono", fieldType: "phone", styleClass: "w-full lg:w-[calc(33%-20px)]"},
            { section: "contact", group:"work_info", name: "city", label: "Ciudad", fieldType: "select", styleClass: "w-full lg:w-[calc(33%-20px)]", options: ciudades},
            { section: "contact", group:"work_info", name: "neighborhood", label: "Barrio", fieldType: "text", styleClass: "w-full lg:w-[calc(33%-20px)]", maxLength: 100}
        ]
    },{
        title: "Contacto alternativo",
        inputs: [
            { subtitle: "Contacto alternativo", tooltip: "Proporcione la información de una persona adicional a la que podamos contactar en caso de que no podamos comunicarnos con usted.", styleClass: "" },
            { section: "contact", group: "alternative_contact", name: "full_name", label: "Nombre completo", fieldType: "text", maxLength: 50},
            { section: "contact", group: "alternative_contact", name: "phone_number", label: "Teléfono", fieldType: "phone" },
            { section: "contact", group: "alternative_contact", name: "neighborhood", label: "Dirección", fieldType: "text", maxLength: 100},
            { section: "contact", group: "alternative_contact", name: "city", label: "Ciudad", fieldType: "select", options: ciudades},
        ]
    },{
        title: "Declaraciones y autorizaciones",
        inputs: [
            { subtitle: "Declaraciones y autorizaciones" },
            { section: "contact", name: "approves_data_handling_policy_documents", label: "documents", fieldType: "checkbox" },
            { section: "contact", name: "approves_funds_origins", label: "origins", fieldType: "checkbox" },
            { section: "contact", name: "approves_terms_finmaq_go", label: "finmaqgo", fieldType: "checkbox" },
        ]
    }
]

const formFieldsJuridica: FormFieldsProps[] = [
    {
        title: "Datos Empresa",
        inputs: [
            { subtitle: "Datos Empresa", tooltip: "Proporcione la información de una persona adicional a la que podamos contactar en caso de que no podamos comunicarnos con usted.", styleClass: "" },
            { section: "company_info", name: "company_name", label: "Razón social", fieldType: "text", previousLock: true },
            { section: "contact", name: "cellphone_number", label: "Teléfono", fieldType: "phone" },
            { section: "company_info", name: "identity_number", label: "NIT", fieldType: "text", previousLock: true },
            { section: "products", name: "created_at", label: "Fecha de solicitud de credito", fieldType: "date", previousLock: true },
            { section: "company_info", name: "company_creation_date", label: "Fecha de constitución", fieldType: "date", tooltip: "Indique la fecha exacta en la que su empresa fue registrada oficialmente como una entidad legal. Esto generalmente se encuentra en los documentos de constitución de la empresa." },
            { section: "company_info", name: "sector_experience_months", label: "Meses de experiencia en el sector", fieldType: "number", tooltip: "Ingrese el número total de meses que ha trabajado en el sector relacionado con su negocio. Si tiene experiencia previa en diferentes roles dentro del mismo sector, asegúrese de sumar todos los meses para obtener un total.", maxLength: 3},
            { section: "company_info", name: "main_economic_activity", label: "Actividad económica principal", fieldType: "text", tooltip: "Indique de manera clara y específica cuál es la actividad principal de su empresa. Esto puede incluir la producción de bienes, prestación de servicios, comercio, entre otros.", maxLength: 100},
            { section: "company_info", name: "ciiu_code", label: "Código CIIU", fieldType: "text", tooltip: "Ingrese el código CIIU correspondiente a la actividad económica principal de su empresa. Puede encontrar este código en https://linea.ccb.org.co/descripcionciiu/", maxLength: 4},
            { section: "contact", name: "email", label: "Correo electrónico", fieldType: "email", styleClass: "w-full lg:w-[calc(33%-20px)]", previousLock: true},
            { section: "contact", group: "work_info", name: "address", label: "Dirección", fieldType: "text", styleClass: "w-full lg:w-[calc(33%-20px)]", maxLength: 100},
            { section: "contact", group: "work_info", name: "city", label: "Ciudad", fieldType: "select", styleClass: "w-full lg:w-[calc(33%-20px)]", options: ciudades},
        ]
    },{
        title: "Datos del representante legal",
        inputs: [
            { subtitle: "Datos del representante legal" },
            { section: "contact", name: "first_name", label: "Nombre completo", fieldType: "text", styleClass: "w-full lg:w-[calc(33%-20px)]", previousLock: true},
            { section: "contact", name: "identity_type", label: "Tipo de documento", fieldType: "select", styleClass: "w-full lg:w-[calc(33%-20px)]", options:[
                { label: "Cédula de ciudadanía",  value: "CC" },
                { label: "Cédula de extranjería", value: "CE" },
                { label: "Pasaporte", value: "PASSPORT" }
            ] },
            { section: "contact", name: "identity_number", label: "Número de documento", fieldType: "text", styleClass: "w-full lg:w-[calc(33%-20px)]", previousLock: true },
            { section: "contact", name: "born_date", label: "Fecha de nacimiento", fieldType: "date" },
            { section: "contact", name: "nationality", label: "Nacionalidad", fieldType: "select", options: paises },
            { section: "contact", name: "gender", label: "Género", fieldType: "radio", options: [
                { label: "Femenino",  value: "FEMENINO" },
                { label: "Masculino", value: "MASCULINO" },
                { label: "Otro", value: "OTRO" }
            ]},
            { section: "contact", name: "politically_exposed", label: "PEP (Persona pública o políticamente expuesta)", tooltip: "Personas que cumplen o a quienes se les han confiado funciones públicas", fieldType: "radio", options: [
                { label: "Si",  value: "true" },
                { label: "No", value: "false" }
            ]}
        ]
    },{
        title: "Contacto alternativo",
        inputs: [
            { subtitle: "Contacto alternativo", tooltip: "Proporcione la información de una persona adicional a la que podamos contactar en caso de que no podamos comunicarnos con usted.", styleClass: "" },
            { section: "contact", group: "alternative_contact", name: "full_name", label: "Nombre completo",fieldType: "text", maxLength: 50},
            { section: "contact", group: "alternative_contact", name: "phone_number", label: "Teléfono", fieldType: "phone" },
            { section: "contact", group: "alternative_contact", name: "neighborhood", label: "Dirección", fieldType: "text", maxLength: 100},
            { section: "contact", group: "alternative_contact", name: "city", label: "Ciudad", fieldType: "select", options: ciudades},
        ]
    },{
        title: "Declaraciones y autorizaciones",
        inputs: [
            { subtitle: "Declaraciones y autorizaciones" },
            { section: "contact", name: "approves_data_handling_policy_documents", label: "documents", fieldType: "checkbox" },
            { section: "contact", name: "approves_funds_origins", label: "origins", fieldType: "checkbox" },
            { section: "contact", name: "approves_terms_finmaq_go", label: "finmaqgo", fieldType: "checkbox" },
        ]
    }
]

const formFields = {
    NATURAL: formFieldsNatural,
    JURIDICA: formFieldsJuridica,
};

export default formFields;