import {useState, useEffect} from "react"
import DropdownSelect from "react-dropdown-select";
import { OptionProps } from "app/formulario-solicitud/utilities/formFields";
import ResponsiveTooltip from "app/components-finmaq/responsiveTooltip";
import { ConfigVariables } from "adapters/ports/config-variables";

interface SelectInputProps {
    options: OptionProps[],
    defaultValue: string,
    label?: string,
    section?: string,
    group?: string,
    name: string,
    styleClass?: string,
    tooltip?: string,
    handleChange: ()=> void
  }

const SelectInput = ({options, defaultValue = "", label = "", section, group, name, styleClass, tooltip, handleChange}: SelectInputProps)=>{
    const [selectedValue, setSelectedValue] = useState("");
    const defaultArray = options.find((e)=>e.value === defaultValue)

    const handleSelectChange = (value:any)=>{
        setSelectedValue(value);
        setTimeout(()=>{
            handleChange();
        },1)
    }

    useEffect(()=>{
        if(defaultValue) setSelectedValue(defaultValue)
    },[defaultValue])

    return <div className={`relative h-[40px] mt-[17px] mb-4 font-Poppins font-normal px-1.5 rounded-[10px] border border-[#acb2b9] bg-white relative ${styleClass} field-container-select field-container-${section}_${group}_${name}`}>
        <input type="text" placeholder={`field-${section}_${group}_${name}`} className={`field-${section}_${group}_${name} multistep-input hidden`} value={selectedValue} onChange={handleChange}/>
        <label className="absolute left-[13px] top-[5px] pointer-events-none transition-all duration-300 text-[#171923] border-white text-[#a0aec2]">
            <span className="bg-white">{label}</span>
            {tooltip !== "" && 
                <ResponsiveTooltip text={tooltip} color="#A0AEC0"/>
            }
        </label>
        <DropdownSelect 
            options={options} 
            onChange={(values) => handleSelectChange(values[0]?.value || "")}
            placeholder={label}
            values={defaultArray ? [defaultArray] : []}
            dropdownPosition={"auto"}
            noDataRenderer={
                () => (
                    <div className="text-center p-2">
                        <img className="relative inline-block top-[-2px]" src={ConfigVariables.ICON_ALERT_FAQS} width={24} height={24} alt='' />
                        <p className="inline-block ml-2 text-#000000 text-sm">No se encontraron resultados</p>
                    </div>
                )
            }/>
        <span className="error-message absolute bottom-[-15px] left-[14px] text-[11px] bottom-[-19px] text-[#ff6347] hidden"></span>
    </div>
}

export default SelectInput
