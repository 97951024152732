import ResponsiveTooltip from "app/components-finmaq/responsiveTooltip";
import PhoneInput from 'react-phone-input-2';
import { Link } from "react-router-dom";
import { FieldsProps } from "app/formulario-solicitud/utilities/formFields";
import RadioInput from "app/formulario-solicitud/utilities/inputs/RadioInputs";
import SelectInput from "app/formulario-solicitud/utilities/inputs/SelectInputs";
import CheckboxInput from "app/formulario-solicitud/utilities/inputs/CheckboxInputs";
import NestedSelectInput from "app/formulario-solicitud/utilities/inputs/NestedSelectInput";

interface RenderedInputsProps extends FieldsProps {
    fieldIndex: number
    defaultValue?: string
    previousLock?: boolean
    styleClass?: string,
    defaultDate?: string,
    legalChecks?: {},
    maxLength?: number,
    handleChange?: ()=>void
}

const renderedInputs = ({ fieldIndex,subtitle,tooltip, fieldType, options = [], defaultValue, previousLock = false, styleClass = "", section, group = "", name, label, defaultDate = "", handleChange, legalChecks, maxLength }: RenderedInputsProps)=>{

    if(subtitle !== ""){
        return <h2 key={subtitle} className="relative w-full text-[18px] font-semibold mt-3">
            {subtitle}
            {tooltip !== "" && 
                <ResponsiveTooltip text={tooltip} color="black"/>
            }
        </h2>
    }

    if(fieldType === "text" || fieldType === "email" || fieldType === "number"){
        const lockInputClassName = defaultValue !== "" && previousLock ? "border-[#edf2f7] pointer-events-none" : "border-[#acb2b9]";

        const labelClassName = defaultValue !== "" && previousLock ? "text-[#a0aec0]" : "text-[#171923]";

        return <div className={`h-[40px] mt-[17px] mb-4 font-Poppins font-normal p-1.5 rounded-[10px] border bg-white relative ${lockInputClassName} ${styleClass} field-container-text field-container-${section}_${group}_${name}`} key={`${name}_${fieldIndex}`} >
            <input 
                type={fieldType} 
                className={`multistep-input appearance-none focus:outline-none w-full h-full field-${section}_${group}_${name} ${labelClassName}`} 
                placeholder={label} 
                id={`${name}_${fieldIndex}`} 
                defaultValue={defaultValue}
                onChange={handleChange}
                maxLength={maxLength}/>
            <label htmlFor={`${name}_${fieldIndex}`} className="absolute left-[13px] top-[5px] transition-all duration-300 text-[#718096] border-white text-[#a0aec2]">
                <span className="bg-white">{label}</span>
                {tooltip !== "" && 
                    <ResponsiveTooltip text={tooltip} color="#A0AEC0"/>
                }
            </label>
            <span className="error-message absolute bottom-[-15px] left-[14px] text-[11px] bottom-[-19px] text-[#ff6347] hidden"></span>
        </div>
    }
    
    if(fieldType === "select"){
        return <SelectInput 
            key={`${name}_${fieldIndex}`}
            options={options} 
            defaultValue={defaultValue} 
            label={label}
            section={section} 
            group={group} 
            name={name} 
            styleClass={styleClass}
            tooltip={tooltip}
            handleChange={handleChange}/>
    }

    if(fieldType === "radio"){ 
        return <div className={`relative my-4 lg:my-0 field-container-radio field-${section}_${group}_${name} field-container-${section}_${group}_${name} ${styleClass}`} key={`${name}_${fieldIndex}`}>
            <div className="block my-3">
                <strong>{label}</strong>              
                {tooltip !== "" && 
                    <ResponsiveTooltip text={tooltip} color="black"/>
                }
            </div>
            <div className="flex lg:justify-between xl:justify-start">
                <RadioInput options={options} defaultValue={defaultValue} name={name} handleChange={handleChange}/>
            </div>
        </div> 
    }

    if(fieldType === "phone"){
        return (
            <div className={`${styleClass} relative multistep-phone-focus field-container-phone field-container-${section}_${group}_${name}`} key={`${name}_${fieldIndex}`}>
                <PhoneInput
                    country={'co'} 
                    inputProps={{ name: 'phone' }}
                    enableSearch={true}
                    inputClass={`field-${section}_${group}_${name} !w-full !h-[39px]`}
                    containerClass={"lg:!top-[17px]"}
                    inputStyle={{"border": "1px solid #acb2b9", "borderRadius": "10px"}}
                    buttonStyle={{"borderRadius": "10px", "background": "white", "zIndex": "10"}}
                    value={defaultValue}
                    onChange={handleChange}/>
                <label className="absolute left-[13px] pointer-events-none transition-all duration-300 text-[#a0aec2] border-white">
                    <span className="bg-white">{label}</span>
                    {tooltip !== "" && 
                        <ResponsiveTooltip text={tooltip} color="#A0AEC0"/>
                    }
                </label>
                <span className="error-message absolute bottom-[-2px] left-[14px] text-[11px] bottom-[-19px] text-[#ff6347] hidden"></span>
            </div>
        )
    }

    if(fieldType === "date"){
        const lockInputClassName = fieldType === "date" && previousLock ? "border-[#edf2f7] pointer-events-none" : "border-[#acb2b9]";

        const labelClassName = name === "created_at" ? "text-[#a0aec0]" : "text-[#171923]";
        
        return <div className={`relative h-[40px] mt-[17px] mb-4 font-Poppins font-normal p-1.5 rounded-[10px] border cursor-pointer peer bg-white text-[#A0AEC0] ${lockInputClassName} ${styleClass} field-container-date field-container-${section}_${group}_${name}`} key={`${name}_${fieldIndex}`}>
            <input id={`${name}_${fieldIndex}`} type="date" className={`field-${section}_${group}_${name} w-full h-full focus:outline-none multistep-input ${labelClassName}`} placeholder={label} defaultValue={defaultDate} readOnly={defaultDate !== "" && previousLock} onChange={handleChange}/>
            <label htmlFor={`${name}_${fieldIndex}`} className="absolute left-[13px] top-[5px] transition-all duration-300 text-[#A0AEC0] border-white text-[#a0aec2]">
                <span className="bg-white">{label}</span>
                {tooltip !== "" && 
                    <ResponsiveTooltip text={tooltip} color="#A0AEC0"/>
                }
            </label>
            <span className="error-message absolute bottom-[-15px] left-[14px] text-[11px] bottom-[-19px] text-[#ff6347] hidden"></span>
        </div>
    }

    if(fieldType === "nested_select"){
        return <NestedSelectInput 
            key={`${name}_${fieldIndex}`}
            defaultValueDepartment={defaultValue ? defaultValue.split("|")[0] : ""}
            defaultValueCity={defaultValue ? defaultValue.split("|")[1] : ""}
            handleChange={handleChange}/>
    }

    if(name === "approves_data_handling_policy_documents" && fieldType === "checkbox"){
        const legalDocuments = legalChecks ? legalChecks["approves_data_handling_policy_documents" as keyof typeof legalChecks] : false;

        return <CheckboxInput
            key={"legalDocuments"}
            defaultValue={legalDocuments}
            section={section} 
            group={group} 
            name={name} 
            content={<label className="inline-block text-sm ml-2 w-[calc(100%-27px)] align-top" htmlFor={name}>Autorizo de manera previa, expresa e informada el Tratamiento de mis datos, conforme a la <a className="text-[#3696B2] underline" href="https://www.finmaq.com.co/politicas-de-tratamiento-datos" target="_blank" rel="noreferrer">Política de Datos Personales</a> y <a className="text-[#3696B2] underline" href="https://www.finmaq.com.co/aviso-privacidad" target="_blank" rel="noreferrer">Aviso de Privacidad</a> de FinMaq.</label>}
            handleChange={handleChange}/>
    }
    if(name === "approves_funds_origins" && fieldType === "checkbox"){
        const legalOrigins = legalChecks ? legalChecks["approves_funds_origins" as keyof typeof legalChecks] : false;

        return <CheckboxInput
            key={"legalOrigins"}
            defaultValue={legalOrigins}
            section={section} 
            group={group} 
            name={name} 
            content={<label className="inline-block text-sm ml-2 w-[calc(100%-27px)] align-top" htmlFor={name}>He leído y acepto la <Link className="text-[#3696B2] underline" to="/declaracion-de-fondos" target="_blank" rel="noreferrer"> Declaración de origen de Fondos</Link> / <Link className="text-[#3696B2] underline" to="/autorizaciones" target="_blank" rel="noreferrer">Autorizaciones</Link></label>}
            handleChange={handleChange}/>
    }
    if(name === "approves_terms_finmaq_go" && fieldType === "checkbox"){
        const legalFinmaqgo = legalChecks ? legalChecks["approves_terms_finmaq_go" as keyof typeof legalChecks] : false;

        return <CheckboxInput 
            key={"legalFinmaqgo"}
            defaultValue={legalFinmaqgo}
            section={section} 
            group={group} 
            name={name} 
            content={<label className="inline-block text-sm ml-2 w-[calc(100%-27px)] align-top" htmlFor={name}>He leído y acepto los <Link className="text-[#3696B2] underline" to="/terminos-y-condiciones-uso-aplicacion" target="_blank" rel="noreferrer"> Términos y condiciones</Link> de Uso de la aplicación FinMaq Go</label>}
            handleChange={handleChange}/>
    }
    return null;
}

export default renderedInputs;