import { Button, Card } from "@material-tailwind/react";
import { IoCloseSharp } from "react-icons/io5";
import { createPortal } from "react-dom";
import { ReactNode } from 'react';

interface ModalProps {
    hasCloseButton?: boolean,
    className?: string,
    title?: ReactNode | string,
    boldmessage?: string,
    message?: ReactNode | string,
    isOpen?: boolean,
    imageUrl: string,
    primaryButtonLabel?: string,
    secondaryButtonLabel?: string,
    handlePrimaryButton?: ()=> void
    handleSecondaryButton?: ()=> void
    handleCloseModal?: ()=> void,
}

const Modal = ({hasCloseButton = true, className, title, boldmessage, message, isOpen, imageUrl, primaryButtonLabel = "", secondaryButtonLabel = "", handlePrimaryButton, handleSecondaryButton, handleCloseModal}: ModalProps)=>{
    if(!isOpen) return null
    return (
        createPortal(
            <div className={`fixed top-0 left-0 w-full h-full bg-[#000000] bg-opacity-50 z-[53] animate-appear opacity-0 ${className}`} >
                <div className="absolute top-0 left-0 z-[50] w-full h-full flex justify-center items-center shadow-lg">
                    <Card className="bg-white z-10 rounded-lg w-[95%] max-w-[448px] rounded-[28px] shadow-2xl">
                        {hasCloseButton &&
                            <div className="absolute top-5 right-5">
                                <IoCloseSharp color="#2D3748" className="hover:cursor-pointer w-[22px] h-[22px]" onClick={ handleCloseModal}/>
                            </div>
                        }
                        <div className="flex justify-center mt-[26px]">
                            <img className="mx-auto my-6 max-w-[90%] min-h-[150px]" src={imageUrl} alt="Imagen modal" />
                        </div>
                        {title &&
                            <p className="text-xl mx-auto mt-1 mb-1 max-w-[80%] w-[300px] font-bold text-[#000000] text-center">{title}</p>
                        }
                        {boldmessage &&
                            <div className="text-center m-auto mt-1 mb-4 text-md text-[#2D3748] max-w-[80%] font-semibold">
                                {boldmessage}
                            </div>
                        }
                        <div className="text-center m-auto mt-1 mb-1 text-md text-[#2D3748] max-w-[90%]">
                            {message}
                        </div>
                        <div className="flex px-3 mb-4">
                            { (primaryButtonLabel !== "" && secondaryButtonLabel !== "") &&
                                <>
                                    <Button className="w-auto md:w-[45%] max-w-[400px] h-[40px] m-auto mt-[40px] mb-[16px] bg-[#EDF2F7] rounded-[28px] px-[4vw] sm:px-5" onClick={handleSecondaryButton}>
                                        <p className="text-[16px] font-semibold text-[#1A202C] normal-case">{secondaryButtonLabel}</p></Button>
                        
                                    <Button className="w-auto md:w-[45%] max-w-[400px] h-[40px] m-auto mt-[40px] mb-[16px] bg-[#390094] rounded-[28px] px-[4vw] sm:px-5" onClick={handlePrimaryButton}>
                                        <p className="text-[16px] font-semibold text-white normal-case">{primaryButtonLabel}</p></Button>
                                </>
                            }

                            { (primaryButtonLabel !== "" && secondaryButtonLabel === "") &&
                                <Button className="w-[90%] max-w-[400px] h-[40px] m-auto mt-[40px] mb-[16px] bg-[#390094] rounded-[28px]" onClick={handlePrimaryButton}>
                                <p className="text-[16px] font-semibold text-white normal-case">{primaryButtonLabel}</p></Button>
                            }
                        </div>
                    </Card>
                </div>
            </div>, document.body)
    )
}

export default Modal;