import { useState, useEffect } from 'react';
import { userPool } from './user-pool-cognito';
import { useParams } from 'react-router-dom';
import useProductData from "app/carga-documentos/utilities/useProductData";

export const useCognitoUserAttributes = () => {
  const [email, setEmail] = useState<string | null>(null);
  const [person_type, setPersonType] = useState<string | null>(null);
  const [identity_type, setIdentityType] = useState<string | null>(null);
  const [identity_number, setIdentityNumber] = useState<string | null>(null);
  const [product_id, setProductId] = useState<string | null>(null);

  const { sortedDataByCreatedAt } = useProductData();
  const { productIndex } = useParams();

  useEffect(() => {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.getSession((err: any, session: any) => {
        cognitoUser.getUserAttributes((err: any, attributes: any) => {

          const emailAttribute = attributes?.find((attr: any) => attr.Name === 'email');
          setEmail(emailAttribute ? emailAttribute.Value : null);

          const userIdAttr = attributes?.find((attr: any) => attr.Name === 'custom:userID');
          const userIdValue = userIdAttr ? userIdAttr.Value : null;

          if (userIdValue && userIdValue.includes('/')) {
            const [person_type, identity_type, identity_number] = userIdValue.split('/');
            setPersonType(person_type);
            setIdentityType(identity_type);
            setIdentityNumber(identity_number);
          }
        });
      });
    }else{
      window.location.href = "/#revoked-token";
    }
  }, []);

  useEffect(() => {
    const selectedProductData = sortedDataByCreatedAt.length >= 1 && Number(productIndex) >= 0 ? sortedDataByCreatedAt[Number(productIndex)] : {};
    const product_uuid = (selectedProductData as unknown as { product_uuid: string }).product_uuid;

    if (selectedProductData && product_uuid) {
      setProductId(product_uuid);
    } else {
      setProductId(null);
    }
  }, [productIndex, sortedDataByCreatedAt]);

  return { email, person_type, identity_type, identity_number, product_id };
};
