import DropdownSelect from "react-dropdown-select";
import { useState, useEffect } from "react";
import departmentsAndCities from "app/formulario-solicitud/utilities/departmentsAndCities";
import { ConfigVariables } from "adapters/ports/config-variables";

const departments = Object.keys(departmentsAndCities).map((e)=>({value:e, label:e}));

interface NestedSelectInputProps {
    defaultValueDepartment?: string,
    defaultValueCity?: string,
    handleChange?: ()=> void
}

const NestedSelectInput = ({defaultValueDepartment = "", defaultValueCity = "",handleChange}:NestedSelectInputProps)=>{
    const [departmentValue, setDepartmentValue] = useState("");
    const [cityValue, setCityValue] = useState("");
    const [cities, setCities] = useState([]);

    const defaultDepartmentArray = departments.find((e)=>e.value === defaultValueDepartment)
    const defaultCityArray = cities.find((e)=>e.value === defaultValueCity)

    const handleDepartment = (value: any)=>{
        const selectedDepartment = departmentsAndCities[value as keyof typeof departmentsAndCities];
        const cities = selectedDepartment ? selectedDepartment.cities.map((e)=>e) : [];

        setDepartmentValue(value);
        setCityValue("");
        setCities(cities);
        handleCity("");

        setTimeout(()=>{
            handleChange();
        },1)
    }

    const handleCity = (value:any)=>{
        setCityValue(value)
        setTimeout(()=>{
            handleChange();
        },1)
    }

    useEffect(()=>{
        if(defaultValueDepartment) setDepartmentValue(defaultValueDepartment);
        if(defaultValueCity){
            const selectedDepartment = departmentsAndCities[defaultValueDepartment as keyof typeof departmentsAndCities];
            const cities = selectedDepartment ? selectedDepartment.cities.map((e)=>e) : [];
            setCities(cities);
        }
    },[defaultValueDepartment, defaultValueCity])
    
    return <>
        <div key="nested-one" className={`relative h-[40px] mt-[17px] mb-4 font-Poppins font-normal px-1.5 rounded-[10px] border border-[#acb2b9] bg-white relative w-full lg:w-[calc(50%-20px)] field-container-select field-container-contact_residence_info_department`}>
            <input type="text" placeholder={`field-contact_residence_info_department`} className={`field-contact_residence_info_department multistep-input hidden`} value={departmentValue} onChange={handleChange}/>
            <label className="absolute left-[13px] top-[5px] pointer-events-none transition-all duration-300 text-[#171923] border-white text-[#a0aec2]">
                <span className="bg-white">Departamento</span>
            </label>
            <DropdownSelect 
                options={departments} 
                onChange={(values) => handleDepartment(values[0]?.value || "")}
                placeholder={"Departamento"}
                values={defaultDepartmentArray ? [defaultDepartmentArray] : []}
                dropdownPosition={"auto"}
                noDataRenderer={
                    () => (
                        <div className="text-center p-2">
                            <img className="relative inline-block top-[-2px]" src={ConfigVariables.ICON_ALERT_FAQS} width={24} height={24} alt='icono error' />
                            <p className="inline-block ml-2 text-#000000 text-sm">No se encontraron resultados.</p>
                        </div>
                    )
                }/>
            <span className="error-message absolute bottom-[-15px] left-[14px] text-[11px] bottom-[-19px] text-[#ff6347] hidden"></span>
        </div>
        <div key="nested-two" className={`relative h-[40px] mt-[17px] mb-4 font-Poppins font-normal px-1.5 rounded-[10px] border border-[#acb2b9] bg-white relative w-full lg:w-[calc(50%-20px)] field-container-select field-container-contact_residence_info_city nested-select ${cityValue === "" ? "reset-value" : ""}`}>
            <input type="text" placeholder={`field-contact_residence_info_city`} className={`field-contact_residence_info_city multistep-input hidden`} value={cityValue} onChange={handleChange}/>
            <label className="absolute left-[13px] top-[5px] pointer-events-none transition-all duration-300 text-[#171923] border-white text-[#a0aec2]">
                <span className="bg-white">Ciudad</span>
            </label>
            <DropdownSelect
                options={cities} 
                onChange={(values) => handleCity(values[0]?.value || "")}
                placeholder={"Ciudad"}
                values={defaultCityArray ? [defaultCityArray] : []}
                dropdownPosition={"auto"}
                noDataRenderer={
                    ({props}) => {
                        const {options} = props;
                        const errorMessage = options.length > 0 ? "No se encontraron resultados." : "Porfavor, seleccione el departamento.";

                        return <div className="text-center p-2">
                            <img className="relative inline-block top-[-2px]" src={ConfigVariables.ICON_ALERT_FAQS} width={24} height={24} alt='icono error' />
                            <p className="inline-block ml-2 text-#000000 text-sm">{errorMessage}</p>
                        </div>
                    }
                }/>
            <span className="error-message absolute bottom-[-15px] left-[14px] text-[11px] bottom-[-19px] text-[#ff6347] hidden"></span>
        </div>
    </>
}

export default NestedSelectInput;