export const parseDateWithUTCOffset = (dateString: string, offset: number) => {
    const [datePart, timePart] = dateString.split(', ');
    const [day, month, year] = datePart.split('/').map(Number);
    const [hour, minute, second] = timePart.split(':').map(Number);
    
    const date = new Date(year, month - 1, day, hour + offset, minute, second);
    const newDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    const newTime = `${("0"+date.getHours()).slice(-2)}:${("0"+date.getMinutes()).slice(-2)}:${("0"+date.getSeconds()).slice(-2)}`;
    
    return `${newDate}, ${newTime}`;
}