import { ConfigVariables } from "adapters/ports/config-variables";
import { useEffect } from "react";
import { createPortal } from "react-dom";

interface ToastCargaDocsProps {
    onTimeout: () => void;
}

export default function ToastCargaDocs({ onTimeout }: ToastCargaDocsProps) {
    useEffect(() => {
        const timer = setTimeout(() => {
            onTimeout();
        }, 3000);
        return () => clearTimeout(timer);
    }, [onTimeout]);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };

    return createPortal(
        <div className="fixed bottom-0 left-0 w-full h-auto bg-[#000000] bg-opacity-0 z-[53] animate-appear opacity-0">
            <div className="flex justify-end lg:mb-2 lg:mr-3" onClick={handleClick}>
                <div className="flex bg-[#C6F6D5] w-[400px] text-md text-end text-[#2D3748] p-2">
                    <img src={ConfigVariables.ICON_CHECK_SUCCESS} width={20} height={20} alt="" className="mr-3 ml-3"/>
                    <p className="text-start ml-2">Tu información se guardó satisfactoriamente</p>
                </div>
            </div>
        </div>,
        document.body
    );
}
