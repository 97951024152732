import { ConfigVariables } from "adapters/ports/config-variables";

export default function preloadImages() {
    const imageUrls = Object.values(ConfigVariables).filter((url) =>
        typeof url === "string" && url.startsWith(process.env.REACT_APP_FINMAQ_GO_FILES_URL)
    );

    imageUrls.forEach((url) => {
        const img = new Image();
        img.src = url;
    });
}

preloadImages();
