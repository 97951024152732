import { useState, useEffect, useMemo } from "react";
import LayoutDocumentos from "app/carga-documentos/layouts/layout-documentos"
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import useProductData from "app/carga-documentos/utilities/useProductData";
import useGetDocuments from "app/carga-documentos/utilities/useGetDocuments";
import Modal from "app/components-finmaq/modal"; 
import { ConfigVariables } from "adapters/ports/config-variables";
import { useNavigate } from "react-router-dom";
import FormFields, {FieldsProps} from "app/formulario-solicitud/utilities/formFields"; 
import { parseDateWithUTCOffset } from "app/mis-creditos/utilities/parseDateWithUTCOffset";

const dotsFormat = (x:number)=> {
    if(!x) return;
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

type NestedObject = { [key: string]: any };

function findNestedValue<T>(obj: NestedObject, key: string): T | undefined {
    if (typeof obj !== 'object' || obj === null) {
      return undefined;
    }
    
    if (key in obj) {
      return obj[key] as T;
    }
    
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        const result = findNestedValue<T>(obj[prop], key);
        if (result !== undefined) {
          return result;
        }
      }
    }
    
    return undefined;
}

export default function Creditos() {
    const { contactData, person_type, sortedDataByCreatedAt } = useProductData();
    const { documents } = useGetDocuments();
    const [isModalOpenIntro, setIsModalOpenIntro] = useState(true);
    const [isMoreThanOne, setIsMoreThanOne] = useState(false);
    const [firstStepLength, setFirstStepLength] = useState(0);
    const [showModal, setShowModal] = useState(false)

    const formFieldsByType =  useMemo(()=>FormFields[person_type as keyof typeof FormFields] || [],[person_type]);
    const firstStep = useMemo(()=>formFieldsByType[0]?.inputs || [], [formFieldsByType])

    const handleCloseModalIntro = () => setIsModalOpenIntro(false);

    const navigate = useNavigate();

    const responsesByStatus = (status:string)=>{
        switch(status){
            case "POR_EMPEZAR":
                return {
                    label: "Por empezar",
                    color: "#DD6B20"
                }
            case "CREADO":
            case "EN_RADICACION":
            case "PREAPROBADO":
                return {
                    label: "Por terminar",
                    color: "#D69E2E"
                }
            case "RADICADO":
                return {
                    label: "Radicado",
                    color: "#01B574"
                }
            default:
                return {
                    label: status,
                    color: "#171923"
                }
        }
    }

    const renderedItems = sortedDataByCreatedAt.map((value, productIndex) =>{
        const currentDocuments = documents ? documents[productIndex as keyof typeof documents] : {};

        const financial_amount = (value as unknown as { financial_product: {
            financial_amount: number
        } }).financial_product?.financial_amount;

        const product_state = (value as unknown as { financial_product: {
            product_state: number
        } }).financial_product?.product_state;

        const created_at = (value as unknown as { created_at: string }).created_at;
        const createdAtWithOffset = parseDateWithUTCOffset(created_at, -5);

        const statusColor = (Object.keys(currentDocuments).length > 1 || firstStepLength === 5) ? responsesByStatus(product_state.toString()).color : responsesByStatus("POR_EMPEZAR").color;

        const statusLabel = (Object.keys(currentDocuments).length > 1 || firstStepLength === 5) ? responsesByStatus(product_state.toString()).label : responsesByStatus("POR_EMPEZAR").label;

        return <Link key={productIndex} className="border-t border-[#CBD5E0] py-5 lg:py-2 relative block lg:flex" to={`/carga-documentos/${productIndex}`}>
            <div className="flex py-2 w-[calc(100%-20px)] px-1 lg:px-4 flex-1"><span className="text-[#718096] flex-1 font-medium lg:hidden">Distribuidor</span><span className="text-[#171923] flex-1 pl-1">INNSA</span></div>
            <div className="flex py-2 w-[calc(100%-20px)] px-1 lg:px-4 flex-1"><span className="text-[#718096] flex-1 font-medium lg:hidden">Valor del crédito</span><span className="text-[#171923] flex-1 pl-1">${dotsFormat(financial_amount)}</span></div>
            <div className="flex py-2 w-[calc(100%-20px)] px-1 lg:px-4 flex-1"><span className="text-[#718096] flex-1 font-medium lg:hidden">Fecha</span><span className="text-[#171923] flex-1 pl-1">{createdAtWithOffset}</span></div>
            <div className="flex py-2 w-[calc(100%-20px)] px-1 lg:px-4 flex-1"><span className="text-[#718096] flex-1 font-medium lg:hidden">Estado</span><span className="text-[#171923] flex-1 pl-1" style={{color: statusColor}}>{statusLabel}</span></div>
            <IoIosArrowForward className="absolute top-7 lg:top-4 right-0 w-[22px] h-[22px]"/>
        </Link>
    })

    useEffect(()=>{
        const isModalOpened = localStorage.getItem('isModalOpened')
        if (!isModalOpened) {
            setShowModal(true)
            localStorage.setItem('isModalOpened', 'true')
        }

        if(Object.keys(sortedDataByCreatedAt).length === 1) navigate("/carga-documentos/0");
        if(Object.keys(sortedDataByCreatedAt).length > 1) setIsMoreThanOne(true);

        if(contactData){
            const valuesFirstStep = firstStep.filter(({name, previousLock}:FieldsProps)=>{
                if(previousLock || !name) return false;
                return true
            }).map(({name}:FieldsProps)=> name)

            const valuesLength = valuesFirstStep.filter((name)=>findNestedValue({data: contactData["contact" as keyof typeof contactData]}, name) !== undefined).length;

            setFirstStepLength(valuesLength)
        }
    },[sortedDataByCreatedAt, navigate, isMoreThanOne, contactData, firstStep])

    return (
        <LayoutDocumentos
            hasFooter={false}>
             <>
                {(isMoreThanOne && showModal) &&
                    <Modal
                        boldmessage="Si tiene créditos con Finmaq de alto monto, no podrá visualizarlos en esta plataforma."
                        message="Para obtener información sobre ellos, por favor contacte a su asesor de confianza."
                        imageUrl={ConfigVariables.ALERTA_CREDITOS_MAYORES}
                        isOpen={isModalOpenIntro}
                        primaryButtonLabel="Entendido"
                        handleCloseModal={handleCloseModalIntro}
                        handlePrimaryButton={handleCloseModalIntro}/>
                }
                <div className={`block justify-between ml-2 mr-2 mt-[40px] lg:mt-[80px] xl:mr-[210px] xl:ml-[210px] lg:mt-[116px] ${!isMoreThanOne ? `skeleton-animation` : ``}`}>
                    <div>
                        <p className="text-[#390094] text-3xl lg:text-4xl font-semibold lg:font-bold leading-10 skeleton-opacity">Mis créditos</p>
                        <p className="text-[#718096] text-base lg:text-base skeleton-pulse">INNSA - Créditos de bajo monto</p>
                    </div>
                    <div className="my-16 border-b border-[#CBD5E0]">
                        <div className="hidden lg:flex mb-7">
                            <div className="flex-1 text-left text-[#718096] font-medium px-4">Distribuidor</div>
                            <div className="flex-1 text-left text-[#718096] font-medium px-4">Valor del crédito</div>
                            <div className="flex-1 text-left text-[#718096] font-medium px-4">Fecha</div>
                            <div className="flex-1 text-left text-[#718096] font-medium px-4">Estado</div>
                        </div>
                        {renderedItems}
                        {renderedItems.length === 0 && 
                            <div className="border-t border-[#CBD5E0] py-5 lg:py-2 relative block lg:flex">
                                <div className="flex py-2 w-[calc(100%-20px)] px-4 flex-1"><span className="text-[#718096] flex-1 font-medium lg:hidden">Distribuidor</span><span className="text-[#171923] flex-1 skeleton-pulse">-----------</span></div>
                                <div className="flex py-2 w-[calc(100%-20px)] px-4 flex-1"><span className="text-[#718096] flex-1 font-medium lg:hidden">Valor del crédito</span><span className="text-[#171923] flex-1 skeleton-pulse">-----------</span></div>
                                <div className="flex py-2 w-[calc(100%-20px)] px-4 flex-1"><span className="text-[#718096] flex-1 font-medium lg:hidden">Fecha</span><span className="text-[#171923] flex-1 skeleton-pulse">-----------</span></div>
                                <div className="flex py-2 w-[calc(100%-20px)] px-4 flex-1"><span className="text-[#718096] flex-1 font-medium lg:hidden">Estado</span><span className="text-[#171923] flex-1 skeleton-pulse">-----------</span></div>
                            </div>
                        }
                    </div>
                </div>
            </>
        </LayoutDocumentos>
    )
}