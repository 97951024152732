import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";

import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import preloadImages from "./adapters/ports/pre-load-images";

const root = ReactDOM.createRoot(document.getElementById("root"));
preloadImages();

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

serviceWorkerRegistration.register(); 
