import { Button } from "@material-tailwind/react";
import { ConfigVariables } from "adapters/ports/config-variables";
import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

export default function BeneficiosSolicitudCredito() {
    const navigate = useNavigate();

    const handleOnClick = () => {
        navigate('/comenzar-carga');
    }

    return (
        <div className='h-screen bg-[#ffffff] flex flex-col font-Poppins overflow-auto'>
            <div className="hidden lg:flex flex-grow">
                <div className="w-1/2 flex justify-center items-center mt-[100px]">
                    <div className='ml-10'>
                        <p className='text-[36px] text-[#390094] font-[600] leading-10'>Solicitud de crédito</p>
                        <div className="mt-[87px]">
                            <div className="grid grid-cols-2 gap-x-8 gap-y-12">
                                <div>
                                    <img src={ConfigVariables.IMAGEN_COMPUTADOR_ONBOARDING} alt="img computador" className="w-[63px] h-[64px]"/>
                                    <p className="text-[16px] leading-6 mt-3 ">Proceso <span className="font-semibold">100% digital</span></p>
                                </div>
                                <div>
                                    <img src={ConfigVariables.IMAGEN_CAFE_ONBOARDING} alt="img cafe" className="w-[63px] h-[64px]"/>
                                    <p className="text-[16px] leading-6 mt-3 "><span className="font-semibold">Mínimos</span> requisitos</p>
                                </div>
                                <div>
                                    <img src={ConfigVariables.IMAGEN_MONEDAS_ONBOARDING} alt="img monedas" className="w-[63px] h-[64px]"/>
                                    <p className="text-[16px] leading-6 mt-3 ">Tasas y cuotas <span className="font-semibold">fijas</span></p>
                                </div>
                                <div>
                                    <img src={ConfigVariables.IMAGEN_WALLET_ONBOARDING} alt="img wallet" className="w-[63px] h-[64px]"/>
                                    <p className="text-[16px] leading-6 mt-3 "><span className="font-semibold">Sin costos</span> asociados</p>
                                </div>
                            </div>
                            <div className="mt-12">
                                <Button
                                    placeholder={""}
                                    onClick={handleOnClick}
                                    className="w-[390px] h-[40px] bg-[#390094] leading-2 text-white text-center rounded-[28px] font-semibold flex items-center justify-center normal-case text-lg"
                                >
                                    Continuar <FiArrowRight className="ml-3" />
                                </Button>
                            </div>
                        </div>
                        <div className="text-[#A0AEC0] text-sm mt-20 mb-6 text-center">
                            <p>© 2024 Finmaq. Todos los derechos reservados.</p>
                        </div>
                    </div>
                </div>
                <div className="w-1/2 relative">
                    <div className='w-full h-full rounded-bl-[166px] bg-cover bg-center' style={{ backgroundImage: `url(${ConfigVariables.IMAGEN_PRINCIPAL_ONBOARDING_DESKTOP})`, backgroundSize: 'cover' }}>
                        <img src={ConfigVariables.IMAGEN_LOGO_FINMAQ_DESKTOP} alt="Group" className="absolute mt-20 right-0 mr-16 w-[170px] h-[47px]"/>
                    </div>
                </div>
            </div>
            <div className="lg:hidden flex flex-col items-center justify-between min-h-screen w-full md:flex md:flex-col">
                <div className="flex justify-center items-center rounded-bl-[166px] w-full">
                    <img src={ConfigVariables.IMAGEN_LOGO_FINMAQ_DESKTOP} alt="Group" className="mt-[6vh] w-[170px] h-[47px]" />
                </div>
                <div className="mt-6 w-full flex-1">
                    <p className="text-[30px] text-center text-[#390094] font-[600] leading-10">Solicitud de crédito</p>
                    <div className="mt-8 ml-12 mr-12 ">
                        <div className="grid grid-cols-2 gap-16 md:ml-24 md:mt-32 md:rigth-20">
                            <div className="text-start">
                                <img src={ConfigVariables.IMAGEN_CELULAR_ONBOARDING} alt="img celular" />
                                <p className="text-[16px] leading-6 mt-3">Proceso <span className="font-semibold">100%<br /> digital</span></p>
                            </div>
                            <div className="ml-6">
                                <img src={ConfigVariables.IMAGEN_CAFE_ONBOARDING} alt="img cafe" />
                                <p className="text-start text-[16px] leading-6 mt-3"><span className="font-semibold">Mínimos</span> requisitos</p>
                            </div>
                            <div className="text-start">
                                <img src={ConfigVariables.IMAGEN_MONEDAS_ONBOARDING} alt="img monedas" />
                                <p className="text-[16px] leading-6 mt-3">Tasas<br /> y cuotas <span className="font-semibold">fijas</span></p>
                            </div>
                            <div className="ml-6">
                                <img src={ConfigVariables.IMAGEN_WALLET_ONBOARDING} alt="img wallet" />
                                <p className="text-start text-[16px] leading-6 mt-3"><span className="font-semibold">Sin costos</span><br /> asociados</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full p-5 md:p-14 mt-22 mb-32 md:sticky md:bottom-32">
                    <Button
                        placeholder={""}
                        onClick={handleOnClick}
                        className="w-full h-[40px] bg-[#390094] leading-2 text-white text-center rounded-[28px] font-semibold flex items-center justify-center normal-case text-lg"
                    >
                        Continuar <FiArrowRight className="ml-3" />
                    </Button>
                </div>
            </div>
        </div>
    );
}





