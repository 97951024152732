import { useState } from "react";
import uploadStatus from "app/carga-documentos/utilities/uploadStatus";
import HttpClientService from "http/httpClientService";
import { ConfigVariables } from "adapters/ports/config-variables";
import useProductData from "app/carga-documentos/utilities/useProductData";
import { useNavigate } from "react-router-dom";

interface GetDocumentsProps {
  productIndex: number;
}

const useDocumentState = ({productIndex}:GetDocumentsProps)=> {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileStatus, setFileStatus] = useState<string>(uploadStatus.DISABLE);
  const [documentUrl, setDocumentUrl] = useState<string>("");

  const navigate = useNavigate();

  const {sortedDataByCreatedAt, person_type, identity_type, identity_number} = useProductData();

  const selectedProductData = sortedDataByCreatedAt.length >= 1 && productIndex >= 0 ? sortedDataByCreatedAt[Number(productIndex)] : {};

  const product_uuid = (selectedProductData as unknown as { product_uuid: string }).product_uuid;

  const apiBaseUrl = ConfigVariables.URL_BASE_API;
  const endpoint = `${apiBaseUrl}client-profile/client/documents`;

  const handleInput = (file:File) =>{
    if(file){
      setFileStatus(uploadStatus.READY);
      setSelectedFile(file);
    }else{
      setFileStatus(uploadStatus.DISABLE);
    }
  } 

  const handleSubmit = (document_type:string, callback?: ()=> void)=>{
    if(!selectedFile && callback){
      setFileStatus(uploadStatus.DONE)
      return callback();
    }
    if(!selectedFile) return false;
    const formData = new FormData();

    formData.append("file", selectedFile);
    formData.append("document_type", document_type);
    formData.append("person_type", person_type);
    formData.append("identity_type", identity_type);
    formData.append("identity_number", identity_number);
    formData.append("product_id", product_uuid);

    setFileStatus(uploadStatus.UPLOADING);

    (async () => {
        try {
            await HttpClientService.post<string>(endpoint, formData);
            setFileStatus(uploadStatus.DONE)
            localStorage.setItem(`failedAttempts${document_type}`, "");
            if(callback) callback()
        } catch (error){
            console.log(error)
            setFileStatus(uploadStatus.ERROR)
            const failedAttempts = localStorage.getItem(`failedAttempts${document_type}`) || "";
            localStorage.setItem(`failedAttempts${document_type}`, `${failedAttempts}f`);

            if(error.code === "ECONNABORTED" || localStorage.getItem(`failedAttempts${document_type}`).length >= 3){
              localStorage.setItem(`failedAttempts${document_type}`, "");
              navigate("/error-connection-timed-out");
            }
        }
    })();
  }

  return { fileStatus, setFileStatus, documentUrl, setDocumentUrl, handleInput, handleSubmit };
}

export default useDocumentState;