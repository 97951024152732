import Header from "app/components-finmaq/header-terminos-y-condiciones"

export default function TerminosYCondicionesUsoApp() {
    return (
        <div className="bg-white text-justify w-full min-h-screen">
            <Header></Header>
            <hr className="lg:mt-20 mt-10 lg:ml-20 ml-6 lg:mr-20 mr-6"></hr>
            <div>
                <div className=" lg:mt-10 mt-5 lg:ml-32 ml-10 mr-10 lg:mr-32 w-auto">
                    <div className="lg:text-2xl text-lg font-bold">TERMINOS Y CONDICIONES USO FINMAQ GO APP</div>
                    <div className="lg:mt-12 mt-6 text-md lg:text-lg">Es requisito necesario para diligenciar y enviar una Solicitud de Préstamo de Bajo Monto, esto es, inferior a la suma de $50.000.000 COP, a través del sitio web www.gofinmaq.com.co(en adelante la “APLICACIÓN”) de propiedad de FINANCIAMOS SU MÁQUINA S.A.S (en adelante “FINMAQ”), que lea y acepte los Términos y Condiciones que a continuación se presentan.</div>
                    <div className="lg:text-xl mt-6 lg:mt-12 text-lg font-bold">Aceptación del Usuario.</div>
                    <div className="lg:mt-12 mt-6 text-md lg:text-lg">La utilización del APLICACIÓN y su contenido, constituye un acuerdo legal entre quienes ingresen al mismo y FINMAQ. El Usuario al acceder, navegar o usar el APLICACIÓN de FINMAQ, reconoce que ha leído, entendido y se obliga a cumplir con estos términos y condiciones, y cumplir con todas las leyes aplicables; si el Usuario no está de acuerdo con estos términos y condiciones de uso, le sugerimos que se abstenga de acceder o navegar por el APLICACIÓN.</div>
                    <div className="lg:text-xl mt-6 lg:mt-12 text-lg font-bold">Modificaciones a los términos y condiciones.</div>
                    <div className="lg:mt-12 mt-6 text-md lg:text-lg">FINMAQ se reserva el derecho de revisar y modificar de manera unilateral estos términos y condiciones de uso o la información contenida en el APLICACIÓN en cualquier momento sin aviso previo, mediante la actualización de un anuncio en el mismo sitio. También puede realizar mejoras o cambios en los servicios descritos en este APLICACIÓN en cualquier momento y sin previo aviso.</div>
                    <div className="lg:text-xl mt-6 lg:mt-12 text-lg font-bold">Del acceso al APLICACIÓN.</div>
                    <div className="lg:mt-12 mt-6 text-md lg:text-lg">Para el envió de una Solicitud de Préstamo de Bajo Monto a través del APLICACIÓN, será necesario el registro por parte del Usuario, con ingreso de datos personales fidedignos y definición de una contraseña. El Usuario puede elegir y cambiar la clave para su acceso de administración de la cuenta en cualquier momento, en caso de que se haya registrado, FINMAQ no asume la responsabilidad en caso de que el Usuario entregue dicha clave a terceros. El usuario es el único responsable de mantener su palabra clave (password) y la información de su cuenta. Para disminuir los riesgos FINMAQ recomienda al Usuario salir de su cuenta y cerrar la ventana de su navegador cuando finalice su actividad.</div>
                    <div className="lg:mt-12 mt-6 text-md lg:text-lg">Si bien FINMAQ posee un sistema de protección tecnológico que va desde sus servidores hasta la salida a Internet, ninguna transmisión por Internet puede garantizar su seguridad al <b>100%.</b></div>
                    <div className="lg:mt-12 mt-6 text-md lg:text-lg">Las condiciones de conectividad serán responsabilidad de cada uno de los usuarios. El acceso a este sitio está sujeto a variables no controladas por FINMAQ y propias de Internet. Cuando el Usuario acceda al APLICACIÓN, será responsabilidad del mismo tomar las medidas de seguridad pertinentes para evitar virus u otros agentes nocivos.</div>
                    <div className="lg:text-xl mt-6 lg:mt-12 text-lg font-bold">Certificado Digital de Sitio Seguro.</div>
                    <div className="lg:mt-12 mt-6 text-md lg:text-lg">El APLICACIÓN cuenta con un Certificado Digital de Sitio Seguro, el cual garantiza al usuario que está ingresando a un sitio web confiable y es el sitio real y oficial de FINMAQ. De igual forma, la información que envíe a través del APLICACIÓN que incorpora dicho certificado cuenta con métodos de encriptación que protegen y garantizan la seguridad de la información.</div>
                    <div className="lg:text-xl mt-6 lg:mt-12 text-lg font-bold">Protección de la información personal.</div>
                    <div className="lg:mt-12 mt-6 text-md lg:text-lg">El Usuario acepta que sus datos personales recopilados a través del APLICACIÓN pueden ser almacenados en bases de datos propiedad de FINMAQ, de sociedades relacionadas, o de terceros aliados expresamente autorizados por FINMAQ. Dando cumplimiento al artículo 10 del Decreto 1367 que reglamenta la Ley 1581 de 2012, FINMAQ informa que aloja los datos personales del Usuario en bases de datos bajo estrictos estándares de responsabilidad protegiendo la información.</div>
                    <div className="lg:mt-12 mt-6 text-md lg:text-lg">La información será tratada por FINMAQ de acuerdo con las finalidades indicadas en su Política de Tratamiento de Datos personales, publicada en su sitio web www.finmaq.co y el Usuario por ministerio de la ley tendrá los siguientes derechos: conocer, actualizar y rectificar sus datos personales, así como solicitar la eliminación de sus datos personales cuando así lo considere, siempre que esté al día en sus obligaciones.</div>
                    <div className="lg:text-xl mt-6 lg:mt-12 text-lg font-bold">Confidencialidad de la información.</div>
                    <div className="lg:mt-12 mt-6 text-md lg:text-lg">FINMAQ no compartirá ni revelará la información confidencial con terceros, excepto que tenga expresa autorización de quienes se suscribieron, o cuando ha sido requerido por orden judicial o legal, o para proteger los derechos de propiedad intelectual u otros derechos publicados en su APLICACIÓN.</div>
                    <div className="lg:text-xl mt-6 lg:mt-12 text-lg font-bold">Propiedad intelectual.</div>
                    <div className="lg:mt-12 mt-6 text-md lg:text-lg">FINMAQ es titular de los derechos de propiedad industrial e intelectual referidos al APLICACIÓN y a los contenidos publicados en él, a excepción de los derechos sobre productos y servicios que no son propiedad de esta sociedad, cuyas marcas están registradas a favor de sus respectivos titulares, y como tal son reconocidas por FINMAQ.</div>
                    <div className="lg:mt-12 mt-6 text-md lg:text-lg">FINMAQ no concede ninguna licencia o autorización de uso de ninguna clase sobre sus derechos de propiedad intelectual, secretos empresariales o sobre cualquier otra propiedad o derecho relacionado con el APLICACIÓN y sus contenidos.</div>
                    <div className="lg:text-xl mt-6 lg:mt-12 text-lg font-bold">Límites de responsabilidad.</div>
                    <div className="lg:mt-12 mt-6 text-md lg:text-lg">FINMAQ no garantiza que en algún momento se puedan presentar interrupciones en el servicio del APLICACIÓN o que éste no sea objeto de posibles ataques de seguridad, ni tampoco por fallas en telecomunicaciones, internet, comunicaciones electrónicas, corrupción de archivos, pérdida o robo de datos, virus, spyware, entre otras afectaciones, porque el funcionamiento y la disponibilidad de los sistemas utilizados para acceder al APLICACIÓN, pueden ser impredecibles y pueden interferir ocasionalmente o impedir el acceso a los productos o servicios de FINMAQ; tampoco se por cualquier consecuencia del ingreso indebido, fraudulento o ilícito de terceros a la base de datos y/o por alguna falla técnica en el funcionamiento y/o conservación de datos suministrador a través del APLICACIÓN.</div>
                    <div className="lg:text-xl mt-6 lg:mt-12 text-lg font-bold">Limitación del Uso del APLICACIÓN.</div>
                    <div className="lg:mt-12 mt-6 text-md lg:text-lg">FINMAQ como propietaria del APLICACIÓN, puede cancelar de manera definitiva o provisional a los Usuarios registrados, cuando:</div>
                    <div>
                        <ul>
                            <li className="lg:mt-4 lg:ml-10 ml-3 mt-3 text-md lg:text-lg"> • Se determine que existe suplantación de identidad, así mismo, cuando el Usuario
                            informe que ha sido víctima de suplantación de identidad.</li>
                            <li className="lg:mt-4 lg:ml-10 ml-3 mt-3 text-md lg:text-lg"> • Se haga un uso indebido, de forma preventiva hasta que FINMAQ determine la
                            configuración o no de una conducta lesiva. En caso de que FIN.</li>
                            <li className="lg:mt-4 lg:ml-10 ml-3 mt-3 text-md lg:text-lg"> • Se entenderá como uso indebido del usuario, los eventos que afecten el
                            principio de la buena fe precontractual, como, por ejemplo:</li>
                            <div>
                                <ul>
                                    <li className="lg:mt-4 lg:ml-24 ml-8 mt-3 text-md lg:text-lg">1. Presentar solicitudes a nombre de otra persona sin la debida
                                    autorización, induciendo o manteniendo en error a FINMAQ.</li>
                                    <li className="lg:mt-4 lg:ml-24 ml-8 mt-3 text-md lg:text-lg">2. Crear más de un usuario a nombre de la misma persona.</li>
                                    <li className="lg:mt-4 lg:ml-24 ml-8 mt-3 text-md lg:text-lg">3. Registro y actualización en el APLICACIÓN, sin la documentación que lo
                                    soporte o que sea efectuada sin la debida autorización.</li>
                                </ul>
                            </div>
                        </ul>
                    </div>
                    <div className="lg:text-xl mt-6 lg:mt-12 text-lg font-bold">Contáctenos.</div>
                    <div className="lg:mt-12 mt-6 text-md lg:text-lg">Si el Usuario desea hacer sugerencias a FINMAQ con el fin de mejorar los contenidos, la información y los servicios que se ofrecen en el APLICACIÓN puede escribir al siguiente correo ayuda@finmaq.com.co.</div>
                </div><br></br><br></br><br></br><br></br>
            </div>
        </div> 
    )
}