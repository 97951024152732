const departmentsAndCities = {
    "Amazonas" : {
        value: "Amazonas",
        cities: [
            {label: "El Encanto", value: "El Encanto"},
            {label: "La Chorrera", value: "La Chorrera"},
            {label: "La Pedrera", value: "La Pedrera"},
            {label: "La Victoria", value: "La Victoria"},
            {label: "Leticia", value: "Leticia"},
            {label: "Mirití - Paraná", value: "Mirití - Paraná"},
            {label: "Puerto Alegría", value: "Puerto Alegría"},
            {label: "Puerto Arica", value: "Puerto Arica"},
            {label: "Puerto Nariño", value: "Puerto Nariño"},
            {label: "Puerto Santander", value: "Puerto Santander"},
            {label: "Tarapacá", value: "Tarapacá"}
        ]
    },
    "Antioquia" : {
        value: "Antioquia",
        cities: [
            {label:"Medellín", value:"Medellín"},
            {label:"Abejorral", value:"Abejorral"},
            {label:"Abriaquí", value:"Abriaquí"},
            {label:"Alejandría", value:"Alejandría"},
            {label:"Amagá", value:"Amagá"},
            {label:"Amalfi", value:"Amalfi"},
            {label:"Andes", value:"Andes"},
            {label:"Angelópolis", value:"Angelópolis"},
            {label:"Angostura", value:"Angostura"},
            {label:"Anorí", value:"Anorí"},
            {label:"Santa Fé de Antioquia", value:"Santa Fé de Antioquia"},
            {label:"Anzá", value:"Anzá"},
            {label:"Apartadó", value:"Apartadó"},
            {label:"Arboletes", value:"Arboletes"},
            {label:"Argelia", value:"Argelia"},
            {label:"Armenia", value:"Armenia"},
            {label:"Barbosa", value:"Barbosa"},
            {label:"Belmira", value:"Belmira"},
            {label:"Bello", value:"Bello"},
            {label:"Betania", value:"Betania"},
            {label:"Betulia", value:"Betulia"},
            {label:"Ciudad Bolívar", value:"Ciudad Bolívar"},
            {label:"Briceño", value:"Briceño"},
            {label:"Buriticá", value:"Buriticá"},
            {label:"Cáceres", value:"Cáceres"},
            {label:"Caicedo", value:"Caicedo"},
            {label:"Caldas", value:"Caldas"},
            {label:"Campamento", value:"Campamento"},
            {label:"Cañasgordas", value:"Cañasgordas"},
            {label:"Caracolí", value:"Caracolí"},
            {label:"Caramanta", value:"Caramanta"},
            {label:"Carepa", value:"Carepa"},
            {label:"El Carmen de Viboral", value:"El Carmen de Viboral"},
            {label:"Carolina", value:"Carolina"},
            {label:"Caucasia", value:"Caucasia"},
            {label:"Chigorodó", value:"Chigorodó"},
            {label:"Cisneros", value:"Cisneros"},
            {label:"Cocorná", value:"Cocorná"},
            {label:"Concepción", value:"Concepción"},
            {label:"Concordia", value:"Concordia"},
            {label:"Copacabana", value:"Copacabana"},
            {label:"Dabeiba", value:"Dabeiba"},
            {label:"Donmatías", value:"Donmatías"},
            {label:"Ebéjico", value:"Ebéjico"},
            {label:"El Bagre", value:"El Bagre"},
            {label:"Entrerríos", value:"Entrerríos"},
            {label:"Envigado", value:"Envigado"},
            {label:"Fredonia", value:"Fredonia"},
            {label:"Frontino", value:"Frontino"},
            {label:"Giraldo", value:"Giraldo"},
            {label:"Girardota", value:"Girardota"},
            {label:"Gómez Plata", value:"Gómez Plata"},
            {label:"Granada", value:"Granada"},
            {label:"Guadalupe", value:"Guadalupe"},
            {label:"Guarne", value:"Guarne"},
            {label:"Guatapé", value:"Guatapé"},
            {label:"Heliconia", value:"Heliconia"},
            {label:"Hispania", value:"Hispania"},
            {label:"Itagüí", value:"Itagüí"},
            {label:"Ituango", value:"Ituango"},
            {label:"Jardín", value:"Jardín"},
            {label:"Jericó", value:"Jericó"},
            {label:"La Ceja", value:"La Ceja"},
            {label:"La Estrella", value:"La Estrella"},
            {label:"La Pintada", value:"La Pintada"},
            {label:"La Unión", value:"La Unión"},
            {label:"Liborina", value:"Liborina"},
            {label:"Maceo", value:"Maceo"},
            {label:"Marinilla", value:"Marinilla"},
            {label:"Montebello", value:"Montebello"},
            {label:"Murindó", value:"Murindó"},
            {label:"Mutatá", value:"Mutatá"},
            {label:"Nariño", value:"Nariño"},
            {label:"Necoclí", value:"Necoclí"},
            {label:"Nechí", value:"Nechí"},
            {label:"Olaya", value:"Olaya"},
            {label:"Peñol", value:"Peñol"},
            {label:"Peque", value:"Peque"},
            {label:"Pueblorrico", value:"Pueblorrico"},
            {label:"Puerto Berrío", value:"Puerto Berrío"},
            {label:"Puerto Nare", value:"Puerto Nare"},
            {label:"Puerto Triunfo", value:"Puerto Triunfo"},
            {label:"Remedios", value:"Remedios"},
            {label:"Retiro", value:"Retiro"},
            {label:"Rionegro", value:"Rionegro"},
            {label:"Sabanalarga", value:"Sabanalarga"},
            {label:"Sabaneta", value:"Sabaneta"},
            {label:"Salgar", value:"Salgar"},
            {label:"San Andrés de Cuerquía", value:"San Andrés de Cuerquía"},
            {label:"San Carlos", value:"San Carlos"},
            {label:"San Francisco", value:"San Francisco"},
            {label:"San Jerónimo", value:"San Jerónimo"},
            {label:"San José de la Montaña", value:"San José de la Montaña"},
            {label:"San Juan de Urabá", value:"San Juan de Urabá"},
            {label:"San Luis", value:"San Luis"},
            {label:"San Pedro de Los Milagros", value:"San Pedro de Los Milagros"},
            {label:"San Pedro de Urabá", value:"San Pedro de Urabá"},
            {label:"San Rafael", value:"San Rafael"},
            {label:"San Roque", value:"San Roque"},
            {label:"San Vicente Ferrer", value:"San Vicente Ferrer"},
            {label:"Santa Bárbara", value:"Santa Bárbara"},
            {label:"Santa Rosa de Osos", value:"Santa Rosa de Osos"},
            {label:"Santo Domingo", value:"Santo Domingo"},
            {label:"El Santuario", value:"El Santuario"},
            {label:"Segovia", value:"Segovia"},
            {label:"Sonsón", value:"Sonsón"},
            {label:"Sopetrán", value:"Sopetrán"},
            {label:"Támesis", value:"Támesis"},
            {label:"Tarazá", value:"Tarazá"},
            {label:"Tarso", value:"Tarso"},
            {label:"Titiribí", value:"Titiribí"},
            {label:"Toledo", value:"Toledo"},
            {label:"Turbo", value:"Turbo"},
            {label:"Uramita", value:"Uramita"},
            {label:"Urrao", value:"Urrao"},
            {label:"Valdivia", value:"Valdivia"},
            {label:"Valparaíso", value:"Valparaíso"},
            {label:"Vegachí", value:"Vegachí"},
            {label:"Venecia", value:"Venecia"},
            {label:"Vigía del Fuerte", value:"Vigía del Fuerte"},
            {label:"Yalí", value:"Yalí"},
            {label:"Yarumal", value:"Yarumal"},
            {label:"Yolombó", value:"Yolombó"},
            {label:"Yondó", value:"Yondó"},
            {label:"Zaragoza", value:"Zaragoza"}
        ]
    },
    "Arauca" : {
        value: "Arauca",
        cities: [
            {label: "Arauca", value: "Arauca"},
            {label: "Arauquita", value: "Arauquita"},
            {label: "Cravo Norte", value: "Cravo Norte"},
            {label: "Fortul", value: "Fortul"},
            {label: "Puerto Rondón", value: "Puerto Rondón"},
            {label: "Saravena", value: "Saravena"},
            {label: "Tame", value: "Tame"}
        ]
    },
    "Arch. San Andrés, Prov. y Sta. Catalina" : {
        value: "Arch. San Andrés, Prov. y Sta. Catalina",
        cities: [
            {label: "San Andrés", value: "San Andrés"},
            {label: "Providencia", value: "Providencia"}
        ]
    },
    "Atlántico" : {
        value: "Atlántico",
        cities: [
            {label:"Barranquilla", value:"Barranquilla"},
            {label:"Baranoa", value:"Baranoa"},
            {label:"Campo de la Cruz", value:"Campo de la Cruz"},
            {label:"Candelaria", value:"Candelaria"},
            {label:"Galapa", value:"Galapa"},
            {label:"Juan de Acosta", value:"Juan de Acosta"},
            {label:"Luruaco", value:"Luruaco"},
            {label:"Malambo", value:"Malambo"},
            {label:"Manatí", value:"Manatí"},
            {label:"Palmar de Varela", value:"Palmar de Varela"},
            {label:"Piojó", value:"Piojó"},
            {label:"Polonuevo", value:"Polonuevo"},
            {label:"Ponedera", value:"Ponedera"},
            {label:"Puerto Colombia", value:"Puerto Colombia"},
            {label:"Repelón", value:"Repelón"},
            {label:"Sabanagrande", value:"Sabanagrande"},
            {label:"Sabanalarga", value:"Sabanalarga"},
            {label:"Santa Lucía", value:"Santa Lucía"},
            {label:"Santo Tomás", value:"Santo Tomás"},
            {label:"Soledad", value:"Soledad"},
            {label:"Suan", value:"Suan"},
            {label:"Tubará", value:"Tubará"},
            {label:"Usiacurí", value:"Usiacurí"}
        ]
    },
    "Bogotá. D.C." : {
        value: "Bogotá. D.C.",
        cities: [
            {label:"Bogotá. D.C.", value:"Bogotá. D.C."}
        ]
    },
    "Bolívar" : {
        value: "Bolívar",
        cities: [
            {label: "Cartagena de Indias", value: "Cartagena de Indias"},
            {label: "Achí", value: "Achí"},
            {label: "Altos del Rosario", value: "Altos del Rosario"},
            {label: "Arenal", value: "Arenal"},
            {label: "Arjona", value: "Arjona"},
            {label: "Arroyohondo", value: "Arroyohondo"},
            {label: "Barranco de Loba", value: "Barranco de Loba"},
            {label: "Calamar", value: "Calamar"},
            {label: "Cantagallo", value: "Cantagallo"},
            {label: "Cicuco", value: "Cicuco"},
            {label: "Córdoba", value: "Córdoba"},
            {label: "Clemencia", value: "Clemencia"},
            {label: "El Carmen de Bolívar", value: "El Carmen de Bolívar"},
            {label: "El Guamo", value: "El Guamo"},
            {label: "El Peñón", value: "El Peñón"},
            {label: "Hatillo de Loba", value: "Hatillo de Loba"},
            {label: "Magangué", value: "Magangué"},
            {label: "Mahates", value: "Mahates"},
            {label: "Margarita", value: "Margarita"},
            {label: "María la Baja", value: "María la Baja"},
            {label: "Montecristo", value: "Montecristo"},
            {label: "Santa Cruz de Mompox", value: "Santa Cruz de Mompox"},
            {label: "Morales", value: "Morales"},
            {label: "Norosí", value: "Norosí"},
            {label: "Pinillos", value: "Pinillos"},
            {label: "Regidor", value: "Regidor"},
            {label: "Río Viejo", value: "Río Viejo"},
            {label: "San Cristóbal", value: "San Cristóbal"},
            {label: "San Estanislao", value: "San Estanislao"},
            {label: "San Fernando", value: "San Fernando"},
            {label: "San Jacinto", value: "San Jacinto"},
            {label: "San Jacinto del Cauca", value: "San Jacinto del Cauca"},
            {label: "San Juan Nepomuceno", value: "San Juan Nepomuceno"},
            {label: "San Martín de Loba", value: "San Martín de Loba"},
            {label: "San Pablo", value: "San Pablo"},
            {label: "Santa Catalina", value: "Santa Catalina"},
            {label: "Santa Rosa", value: "Santa Rosa"},
            {label: "Santa Rosa del Sur", value: "Santa Rosa del Sur"},
            {label: "Simití", value: "Simití"},
            {label: "Soplaviento", value: "Soplaviento"},
            {label: "Talaigua Nuevo", value: "Talaigua Nuevo"},
            {label: "Tiquisio", value: "Tiquisio"},
            {label: "Turbaco", value: "Turbaco"},
            {label: "Turbaná", value: "Turbaná"},
            {label: "Villanueva", value: "Villanueva"},
            {label: "Zambrano", value: "Zambrano"}
        ]
    },
    "Boyacá" : {
        value: "Boyacá",
        cities: [
            {label:"Almeida", value: "Almeida"},
            {label:"Aquitania", value: "Aquitania"},
            {label:"Arcabuco", value: "Arcabuco"},
            {label:"Belén", value: "Belén"},
            {label:"Berbeo", value: "Berbeo"},
            {label:"Betéitiva", value: "Betéitiva"},
            {label:"Boavita", value: "Boavita"},
            {label:"Boyacá", value: "Boyacá"},
            {label:"Briceño", value: "Briceño"},
            {label:"Buenavista", value: "Buenavista"},
            {label:"Busbanzá", value: "Busbanzá"},
            {label:"Caldas", value: "Caldas"},
            {label:"Campohermoso", value: "Campohermoso"},
            {label:"Cerinza", value: "Cerinza"},
            {label:"Chinavita", value: "Chinavita"},
            {label:"Chiquinquirá", value: "Chiquinquirá"},
            {label:"Chíquiza", value: "Chíquiza"},
            {label:"Chiscas", value: "Chiscas"},
            {label:"Chita", value: "Chita"},
            {label:"Chitaraque", value: "Chitaraque"},
            {label:"Chivatá", value: "Chivatá"},
            {label:"Chivor", value: "Chivor"},
            {label:"Ciénega", value: "Ciénega"},
            {label:"Cómbita", value: "Cómbita"},
            {label:"Coper", value: "Coper"},
            {label:"Corrales", value: "Corrales"},
            {label:"Covarachía", value: "Covarachía"},
            {label:"Cubará", value: "Cubará"},
            {label:"Cucaita", value: "Cucaita"},
            {label:"Cuítiva", value: "Cuítiva"},
            {label:"Duitama", value: "Duitama"},
            {label:"El Cocuy", value: "El Cocuy"},
            {label:"El Espino", value: "El Espino"},
            {label:"Firavitoba", value: "Firavitoba"},
            {label:"Floresta", value: "Floresta"},
            {label:"Gachantivá", value: "Gachantivá"},
            {label:"Gámeza", value: "Gámeza"},
            {label:"Garagoa", value: "Garagoa"},
            {label:"Guacamayas", value: "Guacamayas"},
            {label:"Guateque", value: "Guateque"},
            {label:"Guayatá", value: "Guayatá"},
            {label:"Güicán de la Sierra", value: "Güicán de la Sierra"},
            {label:"Iza", value: "Iza"},
            {label:"Jenesano", value: "Jenesano"},
            {label:"Jericó", value: "Jericó"},
            {label:"La Capilla", value: "La Capilla"},
            {label:"La Uvita", value: "La Uvita"},
            {label:"La Victoria", value: "La Victoria"},
            {label:"Labranzagrande", value: "Labranzagrande"},
            {label:"Macanal", value: "Macanal"},
            {label:"Maripí", value: "Maripí"},
            {label:"Miraflores", value: "Miraflores"},
            {label:"Mongua", value: "Mongua"},
            {label:"Monguí", value: "Monguí"},
            {label:"Moniquirá", value: "Moniquirá"},
            {label:"Motavita", value: "Motavita"},
            {label:"Muzo", value: "Muzo"},
            {label:"Nobsa", value: "Nobsa"},
            {label:"Nuevo Colón", value: "Nuevo Colón"},
            {label:"Oicatá", value: "Oicatá"},
            {label:"Otanche", value: "Otanche"},
            {label:"Pachavita", value: "Pachavita"},
            {label:"Páez", value: "Páez"},
            {label:"Paipa", value: "Paipa"},
            {label:"Pajarito", value: "Pajarito"},
            {label:"Panqueba", value: "Panqueba"},
            {label:"Pauna", value: "Pauna"},
            {label:"Paya", value: "Paya"},
            {label:"Paz de Río", value: "Paz de Río"},
            {label:"Pesca", value: "Pesca"},
            {label:"Pisba", value: "Pisba"},
            {label:"Puerto Boyacá", value: "Puerto Boyacá"},
            {label:"Quípama", value: "Quípama"},
            {label:"Ramiriquí", value: "Ramiriquí"},
            {label:"Ráquira", value: "Ráquira"},
            {label:"Rondón", value: "Rondón"},
            {label:"Saboyá", value: "Saboyá"},
            {label:"Sáchica", value: "Sáchica"},
            {label:"Samacá", value: "Samacá"},
            {label:"San Eduardo", value: "San Eduardo"},
            {label:"San José de Pare", value: "San José de Pare"},
            {label:"San Luis de Gaceno", value: "San Luis de Gaceno"},
            {label:"San Mateo", value: "San Mateo"},
            {label:"San Miguel de Sema", value: "San Miguel de Sema"},
            {label:"San Pablo de Borbur", value: "San Pablo de Borbur"},
            {label:"Santa María", value: "Santa María"},
            {label:"Santa Rosa de Viterbo", value: "Santa Rosa de Viterbo"},
            {label:"Santa Sofía", value: "Santa Sofía"},
            {label:"Santana", value: "Santana"},
            {label:"Sativanorte", value: "Sativanorte"},
            {label:"Sativasur", value: "Sativasur"},
            {label:"Siachoque", value: "Siachoque"},
            {label:"Soatá", value: "Soatá"},
            {label:"Socha", value: "Socha"},
            {label:"Socotá", value: "Socotá"},
            {label:"Sogamoso", value: "Sogamoso"},
            {label:"Somondoco", value: "Somondoco"},
            {label:"Sora", value: "Sora"},
            {label:"Soracá", value: "Soracá"},
            {label:"Sotaquirá", value: "Sotaquirá"},
            {label:"Susacón", value: "Susacón"},
            {label:"Sutamarchán", value: "Sutamarchán"},
            {label:"Sutatenza", value: "Sutatenza"},
            {label:"Tasco", value: "Tasco"},
            {label:"Tenza", value: "Tenza"},
            {label:"Tibaná", value: "Tibaná"},
            {label:"Tibasosa", value: "Tibasosa"},
            {label:"Tinjacá", value: "Tinjacá"},
            {label:"Tipacoque", value: "Tipacoque"},
            {label:"Toca", value: "Toca"},
            {label:"Togüí", value: "Togüí"},
            {label:"Tópaga", value: "Tópaga"},
            {label:"Tota", value: "Tota"},
            {label:"Tunja", value: "Tunja"},
            {label:"Tununguá", value: "Tununguá"},
            {label:"Turmequé", value: "Turmequé"},
            {label:"Tuta", value: "Tuta"},
            {label:"Tutazá", value: "Tutazá"},
            {label:"Úmbita", value: "Úmbita"},
            {label:"Ventaquemada", value: "Ventaquemada"},
            {label:"Villa de Leyva", value: "Villa de Leyva"},
            {label:"Viracachá", value: "Viracachá"},
            {label:"Zetaquira", value: "Zetaquira"}
        ]
    },
    "Caldas" : {
        value: "Caldas",
        cities: [
            {label:"Aguadas", value: "Aguadas"},
            {label:"Anserma", value: "Anserma"},
            {label:"Aranzazu", value: "Aranzazu"},
            {label:"Belalcázar", value: "Belalcázar"},
            {label:"Chinchiná", value: "Chinchiná"},
            {label:"Filadelfia", value: "Filadelfia"},
            {label:"La Dorada", value: "La Dorada"},
            {label:"La Merced", value: "La Merced"},
            {label:"Manizales", value: "Manizales"},
            {label:"Manzanares", value: "Manzanares"},
            {label:"Marmato", value: "Marmato"},
            {label:"Marquetalia", value: "Marquetalia"},
            {label:"Marulanda", value: "Marulanda"},
            {label:"Neira", value: "Neira"},
            {label:"Norcasia", value: "Norcasia"},
            {label:"Pácora", value: "Pácora"},
            {label:"Palestina", value: "Palestina"},
            {label:"Pensilvania", value: "Pensilvania"},
            {label:"Riosucio", value: "Riosucio"},
            {label:"Risaralda", value: "Risaralda"},
            {label:"Salamina", value: "Salamina"},
            {label:"Samaná", value: "Samaná"},
            {label:"San José", value: "San José"},
            {label:"Supía", value: "Supía"},
            {label:"Victoria", value: "Victoria"},
            {label:"Villamaría", value: "Villamaría"},
            {label:"Viterbo", value: "Viterbo"}
        ]
    },
    "Caquetá" : {
        value: "Caquetá",
        cities: [
            {label:"Albania", value: "Albania"},
            {label:"Belén de Los Andaquíes", value: "Belén de Los Andaquíes"},
            {label:"Cartagena del Chairá", value: "Cartagena del Chairá"},
            {label:"Curillo", value: "Curillo"},
            {label:"El Doncello", value: "El Doncello"},
            {label:"El Paujíl", value: "El Paujíl"},
            {label:"Florencia", value: "Florencia"},
            {label:"La Montañita", value: "La Montañita"},
            {label:"Milán", value: "Milán"},
            {label:"Morelia", value: "Morelia"},
            {label:"Puerto Rico", value: "Puerto Rico"},
            {label:"San José del Fragua", value: "San José del Fragua"},
            {label:"San Vicente del Caguán", value: "San Vicente del Caguán"},
            {label:"Solano", value: "Solano"},
            {label:"Solita", value: "Solita"},
            {label:"Valparaíso", value: "Valparaíso"}
        ]
    },
    "Casanare" : {
        value: "Casanare",
        cities: [
            {label: "Aguazul", value: "Aguazul"},
            {label: "Chámeza", value: "Chámeza"},
            {label: "Hato Corozal", value: "Hato Corozal"},
            {label: "La Salina", value: "La Salina"},
            {label: "Maní", value: "Maní"},
            {label: "Monterrey", value: "Monterrey"},
            {label: "Nunchía", value: "Nunchía"},
            {label: "Orocué", value: "Orocué"},
            {label: "Paz de Ariporo", value: "Paz de Ariporo"},
            {label: "Pore", value: "Pore"},
            {label: "Recetor", value: "Recetor"},
            {label: "Sabanalarga", value: "Sabanalarga"},
            {label: "Sácama", value: "Sácama"},
            {label: "San Luis de Palenque", value: "San Luis de Palenque"},
            {label: "Támara", value: "Támara"},
            {label: "Tauramena", value: "Tauramena"},
            {label: "Trinidad", value: "Trinidad"},
            {label: "Villanueva", value: "Villanueva"},
            {label: "Yopal", value: "Yopal"}
        ]
    },
    "Cauca" : {
        value: "Cauca",
        cities: [
            {label:"Almaguer", value: "Almaguer"},
            {label:"Argelia", value: "Argelia"},
            {label:"Balboa", value: "Balboa"},
            {label:"Bolívar", value: "Bolívar"},
            {label:"Buenos Aires", value: "Buenos Aires"},
            {label:"Cajibío", value: "Cajibío"},
            {label:"Caldono", value: "Caldono"},
            {label:"Caloto", value: "Caloto"},
            {label:"Corinto", value: "Corinto"},
            {label:"El Tambo", value: "El Tambo"},
            {label:"Florencia", value: "Florencia"},
            {label:"Guachené", value: "Guachené"},
            {label:"Guapi", value: "Guapi"},
            {label:"Inzá", value: "Inzá"},
            {label:"Jambaló", value: "Jambaló"},
            {label:"La Sierra", value: "La Sierra"},
            {label:"La Vega", value: "La Vega"},
            {label:"López de Micay", value: "López de Micay"},
            {label:"Mercaderes", value: "Mercaderes"},
            {label:"Miranda", value: "Miranda"},
            {label:"Morales", value: "Morales"},
            {label:"Padilla", value: "Padilla"},
            {label:"Páez", value: "Páez"},
            {label:"Patía", value: "Patía"},
            {label:"Piamonte", value: "Piamonte"},
            {label:"Piendamó - Tunía", value: "Piendamó - Tunía"},
            {label:"Popayán", value: "Popayán"},
            {label:"Puerto Tejada", value: "Puerto Tejada"},
            {label:"Puracé", value: "Puracé"},
            {label:"Rosas", value: "Rosas"},
            {label:"San Sebastián", value: "San Sebastián"},
            {label:"Santa Rosa", value: "Santa Rosa"},
            {label:"Santander de Quilichao", value: "Santander de Quilichao"},
            {label:"Silvia", value: "Silvia"},
            {label:"Sotará Paispamba", value: "Sotará Paispamba"},
            {label:"Suárez", value: "Suárez"},
            {label:"Sucre", value: "Sucre"},
            {label:"Timbío", value: "Timbío"},
            {label:"Timbiquí", value: "Timbiquí"},
            {label:"Toribío", value: "Toribío"},
            {label:"Totoró", value: "Totoró"},
            {label:"Villa Rica", value: "Villa Rica"}
        ]
    },
    "Cesar" : {
        value: "Cesar",
        cities: [
            {label: "Aguachica", value: "Aguachica"},
            {label: "Agustín Codazzi", value: "Agustín Codazzi"},
            {label: "Astrea", value: "Astrea"},
            {label: "Becerril", value: "Becerril"},
            {label: "Bosconia", value: "Bosconia"},
            {label: "Chimichagua", value: "Chimichagua"},
            {label: "Chiriguaná", value: "Chiriguaná"},
            {label: "Curumaní", value: "Curumaní"},
            {label: "El Copey", value: "El Copey"},
            {label: "El Paso", value: "El Paso"},
            {label: "Gamarra", value: "Gamarra"},
            {label: "González", value: "González"},
            {label: "La Gloria", value: "La Gloria"},
            {label: "La Jagua de Ibirico", value: "La Jagua de Ibirico"},
            {label: "La Paz", value: "La Paz"},
            {label: "Manaure Balcón del Cesar", value: "Manaure Balcón del Cesar"},
            {label: "Pailitas", value: "Pailitas"},
            {label: "Pelaya", value: "Pelaya"},
            {label: "Pueblo Bello", value: "Pueblo Bello"},
            {label: "Río de Oro", value: "Río de Oro"},
            {label: "San Alberto", value: "San Alberto"},
            {label: "San Diego", value: "San Diego"},
            {label: "San Martín", value: "San Martín"},
            {label: "Tamalameque", value: "Tamalameque"},
            {label: "Valledupar", value: "Valledupar"}
        ]
    },
    "Chocó" : {
        value: "Chocó",
        cities: [
            {label:"Acandí", value: "Acandí"},
            {label:"Alto Baudó", value: "Alto Baudó"},
            {label:"Atrato", value: "Atrato"},
            {label:"Bagadó", value: "Bagadó"},
            {label:"Bahía Solano", value: "Bahía Solano"},
            {label:"Bajo Baudó", value: "Bajo Baudó"},
            {label:"Bojayá", value: "Bojayá"},
            {label:"Carmen del Darién", value: "Carmen del Darién"},
            {label:"Cértegui", value: "Cértegui"},
            {label:"Condoto", value: "Condoto"},
            {label:"El Cantón del San Pablo", value: "El Cantón del San Pablo"},
            {label:"El Carmen de Atrato", value: "El Carmen de Atrato"},
            {label:"El Litoral del San Juan", value: "El Litoral del San Juan"},
            {label:"Istmina", value: "Istmina"},
            {label:"Juradó", value: "Juradó"},
            {label:"Lloró", value: "Lloró"},
            {label:"Medio Atrato", value: "Medio Atrato"},
            {label:"Medio Baudó", value: "Medio Baudó"},
            {label:"Medio San Juan", value: "Medio San Juan"},
            {label:"Nóvita", value: "Nóvita"},
            {label:"Nuquí", value: "Nuquí"},
            {label:"Quibdó", value: "Quibdó"},
            {label:"Río Iró", value: "Río Iró"},
            {label:"Río Quito", value: "Río Quito"},
            {label:"Riosucio", value: "Riosucio"},
            {label:"San José del Palmar", value: "San José del Palmar"},
            {label:"Sipí", value: "Sipí"},
            {label:"Tadó", value: "Tadó"},
            {label:"Unguía", value: "Unguía"},
            {label:"Unión Panamericana", value: "Unión Panamericana"}
        ]
    },
    "Córdoba" : {
        value: "Córdoba",
        cities: [
            {label: "Ayapel", value: "Ayapel"},
            {label: "Buenavista", value: "Buenavista"},
            {label: "Canalete", value: "Canalete"},
            {label: "Cereté", value: "Cereté"},
            {label: "Chimá", value: "Chimá"},
            {label: "Chinú", value: "Chinú"},
            {label: "Ciénaga de Oro", value: "Ciénaga de Oro"},
            {label: "Cotorra", value: "Cotorra"},
            {label: "La Apartada", value: "La Apartada"},
            {label: "Lorica", value: "Lorica"},
            {label: "Los Córdobas", value: "Los Córdobas"},
            {label: "Momil", value: "Momil"},
            {label: "Moñitos", value: "Moñitos"},
            {label: "Montelíbano", value: "Montelíbano"},
            {label: "Montería", value: "Montería"},
            {label: "Planeta Rica", value: "Planeta Rica"},
            {label: "Pueblo Nuevo", value: "Pueblo Nuevo"},
            {label: "Puerto Escondido", value: "Puerto Escondido"},
            {label: "Puerto Libertador", value: "Puerto Libertador"},
            {label: "Purísima de la Concepción", value: "Purísima de la Concepción"},
            {label: "Sahagún", value: "Sahagún"},
            {label: "San Andrés de Sotavento", value: "San Andrés de Sotavento"},
            {label: "San Antero", value: "San Antero"},
            {label: "San Bernardo del Viento", value: "San Bernardo del Viento"},
            {label: "San Carlos", value: "San Carlos"},
            {label: "San José de Uré", value: "San José de Uré"},
            {label: "San Pelayo", value: "San Pelayo"},
            {label: "Tierralta", value: "Tierralta"},
            {label: "Tuchín", value: "Tuchín"},
            {label: "Valencia", value: "Valencia"}
        ]
    },
    "Cundinamarca" : {
        value: "Cundinamarca",
        cities: [
            {label: "Agua de Dios", value: "Agua de Dios"},
            {label: "Albán", value: "Albán"},
            {label: "Anapoima", value: "Anapoima"},
            {label: "Anolaima", value: "Anolaima"},
            {label: "Apulo", value: "Apulo"},
            {label: "Arbeláez", value: "Arbeláez"},
            {label: "Beltrán", value: "Beltrán"},
            {label: "Bituima", value: "Bituima"},
            {label: "Bojacá", value: "Bojacá"},
            {label: "Cabrera", value: "Cabrera"},
            {label: "Cachipay", value: "Cachipay"},
            {label: "Cajicá", value: "Cajicá"},
            {label: "Caparrapí", value: "Caparrapí"},
            {label: "Cáqueza", value: "Cáqueza"},
            {label: "Carmen de Carupa", value: "Carmen de Carupa"},
            {label: "Chaguaní", value: "Chaguaní"},
            {label: "Chía", value: "Chía"},
            {label: "Chipaque", value: "Chipaque"},
            {label: "Choachí", value: "Choachí"},
            {label: "Chocontá", value: "Chocontá"},
            {label: "Cogua", value: "Cogua"},
            {label: "Cota", value: "Cota"},
            {label: "Cucunubá", value: "Cucunubá"},
            {label: "El Colegio", value: "El Colegio"},
            {label: "El Peñón", value: "El Peñón"},
            {label: "El Rosal", value: "El Rosal"},
            {label: "Facatativá", value: "Facatativá"},
            {label: "Fómeque", value: "Fómeque"},
            {label: "Fosca", value: "Fosca"},
            {label: "Funza", value: "Funza"},
            {label: "Fúquene", value: "Fúquene"},
            {label: "Fusagasugá", value: "Fusagasugá"},
            {label: "Gachalá", value: "Gachalá"},
            {label: "Gachancipá", value: "Gachancipá"},
            {label: "Gachetá", value: "Gachetá"},
            {label: "Gama", value: "Gama"},
            {label: "Girardot", value: "Girardot"},
            {label: "Granada", value: "Granada"},
            {label: "Guachetá", value: "Guachetá"},
            {label: "Guaduas", value: "Guaduas"},
            {label: "Guasca", value: "Guasca"},
            {label: "Guataquí", value: "Guataquí"},
            {label: "Guatavita", value: "Guatavita"},
            {label: "Guayabal de Síquima", value: "Guayabal de Síquima"},
            {label: "Guayabetal", value: "Guayabetal"},
            {label: "Gutiérrez", value: "Gutiérrez"},
            {label: "Jerusalén", value: "Jerusalén"},
            {label: "Junín", value: "Junín"},
            {label: "La Calera", value: "La Calera"},
            {label: "La Mesa", value: "La Mesa"},
            {label: "La Palma", value: "La Palma"},
            {label: "La Peña", value: "La Peña"},
            {label: "La Vega", value: "La Vega"},
            {label: "Lenguazaque", value: "Lenguazaque"},
            {label: "Machetá", value: "Machetá"},
            {label: "Madrid", value: "Madrid"},
            {label: "Manta", value: "Manta"},
            {label: "Medina", value: "Medina"},
            {label: "Mosquera", value: "Mosquera"},
            {label: "Nariño", value: "Nariño"},
            {label: "Nemocón", value: "Nemocón"},
            {label: "Nilo", value: "Nilo"},
            {label: "Nimaima", value: "Nimaima"},
            {label: "Nocaima", value: "Nocaima"},
            {label: "Pacho", value: "Pacho"},
            {label: "Paime", value: "Paime"},
            {label: "Pandi", value: "Pandi"},
            {label: "Paratebueno", value: "Paratebueno"},
            {label: "Pasca", value: "Pasca"},
            {label: "Puerto Salgar", value: "Puerto Salgar"},
            {label: "Pulí", value: "Pulí"},
            {label: "Quebradanegra", value: "Quebradanegra"},
            {label: "Quetame", value: "Quetame"},
            {label: "Quipile", value: "Quipile"},
            {label: "Ricaurte", value: "Ricaurte"},
            {label: "San Antonio del Tequendama", value: "San Antonio del Tequendama"},
            {label: "San Bernardo", value: "San Bernardo"},
            {label: "San Cayetano", value: "San Cayetano"},
            {label: "San Francisco", value: "San Francisco"},
            {label: "San Juan de Rioseco", value: "San Juan de Rioseco"},
            {label: "Sasaima", value: "Sasaima"},
            {label: "Sesquilé", value: "Sesquilé"},
            {label: "Sibaté", value: "Sibaté"},
            {label: "Silvania", value: "Silvania"},
            {label: "Simijaca", value: "Simijaca"},
            {label: "Soacha", value: "Soacha"},
            {label: "Sopó", value: "Sopó"},
            {label: "Subachoque", value: "Subachoque"},
            {label: "Suesca", value: "Suesca"},
            {label: "Supatá", value: "Supatá"},
            {label: "Susa", value: "Susa"},
            {label: "Sutatausa", value: "Sutatausa"},
            {label: "Tabio", value: "Tabio"},
            {label: "Tausa", value: "Tausa"},
            {label: "Tena", value: "Tena"},
            {label: "Tenjo", value: "Tenjo"},
            {label: "Tibacuy", value: "Tibacuy"},
            {label: "Tibirita", value: "Tibirita"},
            {label: "Tocaima", value: "Tocaima"},
            {label: "Tocancipá", value: "Tocancipá"},
            {label: "Topaipí", value: "Topaipí"},
            {label: "Ubalá", value: "Ubalá"},
            {label: "Ubaque", value: "Ubaque"},
            {label: "Une", value: "Une"},
            {label: "Útica", value: "Útica"},
            {label: "Venecia", value: "Venecia"},
            {label: "Vergara", value: "Vergara"},
            {label: "Vianí", value: "Vianí"},
            {label: "Villa de San Diego de Ubaté", value: "Villa de San Diego de Ubaté"},
            {label: "Villagómez", value: "Villagómez"},
            {label: "Villapinzón", value: "Villapinzón"},
            {label: "Villeta", value: "Villeta"},
            {label: "Viotá", value: "Viotá"},
            {label: "Yacopí", value: "Yacopí"},
            {label: "Zipacón", value: "Zipacón"},
            {label: "Zipaquirá", value: "Zipaquirá"}
        ]
    },
    "Guainía" : {
        value: "Guainía",
        cities: [
            {label: "Barrancominas", value: "Barrancominas"},
            {label: "Cacahual", value: "Cacahual"},
            {label: "Inírida", value: "Inírida"},
            {label: "La Guadalupe", value: "La Guadalupe"},
            {label: "Morichal", value: "Morichal"},
            {label: "Pana Pana", value: "Pana Pana"},
            {label: "Puerto Colombia", value: "Puerto Colombia"},
            {label: "San Felipe", value: "San Felipe"}
        ]
    },
    "Guaviare" : {
        value: "Guaviare",
        cities: [
            {label: "Calamar", value: "Calamar"},
            {label: "El Retorno", value: "El Retorno"},
            {label: "Miraflores", value: "Miraflores"},
            {label: "San José del Guaviare", value: "San José del Guaviare"}
        ]
    },
    "Huila" : {
        value: "Huila",
        cities: [
            {label: "Acevedo", value: "Acevedo"},
            {label: "Agrado", value: "Agrado"},
            {label: "Aipe", value: "Aipe"},
            {label: "Algeciras", value: "Algeciras"},
            {label: "Altamira", value: "Altamira"},
            {label: "Baraya", value: "Baraya"},
            {label: "Campoalegre", value: "Campoalegre"},
            {label: "Colombia", value: "Colombia"},
            {label: "Elías", value: "Elías"},
            {label: "Garzón", value: "Garzón"},
            {label: "Gigante", value: "Gigante"},
            {label: "Guadalupe", value: "Guadalupe"},
            {label: "Hobo", value: "Hobo"},
            {label: "Íquira", value: "Íquira"},
            {label: "Isnos", value: "Isnos"},
            {label: "La Argentina", value: "La Argentina"},
            {label: "La Plata", value: "La Plata"},
            {label: "Nátaga", value: "Nátaga"},
            {label: "Neiva", value: "Neiva"},
            {label: "Oporapa", value: "Oporapa"},
            {label: "Paicol", value: "Paicol"},
            {label: "Palermo", value: "Palermo"},
            {label: "Palestina", value: "Palestina"},
            {label: "Pital", value: "Pital"},
            {label: "Pitalito", value: "Pitalito"},
            {label: "Rivera", value: "Rivera"},
            {label: "Saladoblanco", value: "Saladoblanco"},
            {label: "San Agustín", value: "San Agustín"},
            {label: "Santa María", value: "Santa María"},
            {label: "Suaza", value: "Suaza"},
            {label: "Tarqui", value: "Tarqui"},
            {label: "Tello", value: "Tello"},
            {label: "Teruel", value: "Teruel"},
            {label: "Tesalia", value: "Tesalia"},
            {label: "Timaná", value: "Timaná"},
            {label: "Villavieja", value: "Villavieja"},
            {label: "Yaguará", value: "Yaguará"}
        ]
    },
    "La Guajira" : {
        value: "La Guajira",
        cities: [
            {nmae: "Albania", value: "Albania"},
            {nmae: "Barrancas", value: "Barrancas"},
            {nmae: "Dibulla", value: "Dibulla"},
            {nmae: "Distracción", value: "Distracción"},
            {nmae: "El Molino", value: "El Molino"},
            {nmae: "Fonseca", value: "Fonseca"},
            {nmae: "Hatonuevo", value: "Hatonuevo"},
            {nmae: "La Jagua del Pilar", value: "La Jagua del Pilar"},
            {nmae: "Maicao", value: "Maicao"},
            {nmae: "Manaure", value: "Manaure"},
            {nmae: "Riohacha", value: "Riohacha"},
            {nmae: "San Juan del Cesar", value: "San Juan del Cesar"},
            {nmae: "Uribia", value: "Uribia"},
            {nmae: "Urumita", value: "Urumita"},
            {nmae: "Villanueva", value: "Villanueva"}
        ]
    },
    "Magdalena" : {
        value: "Magdalena",
        cities: [
            {label: "Algarrobo", value: "Algarrobo"},
            {label: "Aracataca", value: "Aracataca"},
            {label: "Ariguaní", value: "Ariguaní"},
            {label: "Cerro de San Antonio", value: "Cerro de San Antonio"},
            {label: "Chivolo", value: "Chivolo"},
            {label: "Ciénaga", value: "Ciénaga"},
            {label: "Concordia", value: "Concordia"},
            {label: "El Banco", value: "El Banco"},
            {label: "El Piñón", value: "El Piñón"},
            {label: "El Retén", value: "El Retén"},
            {label: "Fundación", value: "Fundación"},
            {label: "Guamal", value: "Guamal"},
            {label: "Nueva Granada", value: "Nueva Granada"},
            {label: "Pedraza", value: "Pedraza"},
            {label: "Pijiño del Carmen", value: "Pijiño del Carmen"},
            {label: "Pivijay", value: "Pivijay"},
            {label: "Plato", value: "Plato"},
            {label: "Puebloviejo", value: "Puebloviejo"},
            {label: "Remolino", value: "Remolino"},
            {label: "Sabanas de San Ángel", value: "Sabanas de San Ángel"},
            {label: "Salamina", value: "Salamina"},
            {label: "San Sebastián de Buenavista", value: "San Sebastián de Buenavista"},
            {label: "San Zenón", value: "San Zenón"},
            {label: "Santa Ana", value: "Santa Ana"},
            {label: "Santa Bárbara de Pinto", value: "Santa Bárbara de Pinto"},
            {label: "Santa Marta", value: "Santa Marta"},
            {label: "Sitionuevo", value: "Sitionuevo"},
            {label: "Tenerife", value: "Tenerife"},
            {label: "Zapayán", value: "Zapayán"},
            {label: "Zona Bananera", value: "Zona Bananera"}
        ]
    },
    "Meta" : {
        value: "Meta",
        cities: [
            {label: "Acacías", value: "Acacías"},
            {label: "Barranca de Upía", value: "Barranca de Upía"},
            {label: "Cabuyaro", value: "Cabuyaro"},
            {label: "Castilla la Nueva", value: "Castilla la Nueva"},
            {label: "Cubarral", value: "Cubarral"},
            {label: "Cumaral", value: "Cumaral"},
            {label: "El Calvario", value: "El Calvario"},
            {label: "El Castillo", value: "El Castillo"},
            {label: "El Dorado", value: "El Dorado"},
            {label: "Fuente de Oro", value: "Fuente de Oro"},
            {label: "Granada", value: "Granada"},
            {label: "Guamal", value: "Guamal"},
            {label: "La Macarena", value: "La Macarena"},
            {label: "Lejanías", value: "Lejanías"},
            {label: "Mapiripán", value: "Mapiripán"},
            {label: "Mesetas", value: "Mesetas"},
            {label: "Puerto Concordia", value: "Puerto Concordia"},
            {label: "Puerto Gaitán", value: "Puerto Gaitán"},
            {label: "Puerto Lleras", value: "Puerto Lleras"},
            {label: "Puerto López", value: "Puerto López"},
            {label: "Puerto Rico", value: "Puerto Rico"},
            {label: "Restrepo", value: "Restrepo"},
            {label: "San Carlos de Guaroa", value: "San Carlos de Guaroa"},
            {label: "San Juan de Arama", value: "San Juan de Arama"},
            {label: "San Juanito", value: "San Juanito"},
            {label: "San Martín", value: "San Martín"},
            {label: "Uribe", value: "Uribe"},
            {label: "Villavicencio", value: "Villavicencio"},
            {label: "Vistahermosa", value: "Vistahermosa"}
        ]
    },
    "Nariño" : {
        value: "Nariño",
        cities: [
            {label: "Albán", value: "Albán"},
            {label: "Aldana", value: "Aldana"},
            {label: "Ancuya", value: "Ancuya"},
            {label: "Arboleda", value: "Arboleda"},
            {label: "Barbacoas", value: "Barbacoas"},
            {label: "Belén", value: "Belén"},
            {label: "Buesaco", value: "Buesaco"},
            {label: "Chachagüí", value: "Chachagüí"},
            {label: "Colón", value: "Colón"},
            {label: "Consacá", value: "Consacá"},
            {label: "Contadero", value: "Contadero"},
            {label: "Córdoba", value: "Córdoba"},
            {label: "Cuaspud Carlosama", value: "Cuaspud Carlosama"},
            {label: "Cumbal", value: "Cumbal"},
            {label: "Cumbitara", value: "Cumbitara"},
            {label: "El Charco", value: "El Charco"},
            {label: "El Peñol", value: "El Peñol"},
            {label: "El Rosario", value: "El Rosario"},
            {label: "El Tablón de Gómez", value: "El Tablón de Gómez"},
            {label: "El Tambo", value: "El Tambo"},
            {label: "Francisco Pizarro", value: "Francisco Pizarro"},
            {label: "Funes", value: "Funes"},
            {label: "Guachucal", value: "Guachucal"},
            {label: "Guaitarilla", value: "Guaitarilla"},
            {label: "Gualmatán", value: "Gualmatán"},
            {label: "Iles", value: "Iles"},
            {label: "Imués", value: "Imués"},
            {label: "Ipiales", value: "Ipiales"},
            {label: "La Cruz", value: "La Cruz"},
            {label: "La Florida", value: "La Florida"},
            {label: "La Llanada", value: "La Llanada"},
            {label: "La Tola", value: "La Tola"},
            {label: "La Unión", value: "La Unión"},
            {label: "Leiva", value: "Leiva"},
            {label: "Linares", value: "Linares"},
            {label: "Los Andes", value: "Los Andes"},
            {label: "Magüí", value: "Magüí"},
            {label: "Mallama", value: "Mallama"},
            {label: "Mosquera", value: "Mosquera"},
            {label: "Nariño", value: "Nariño"},
            {label: "Olaya Herrera", value: "Olaya Herrera"},
            {label: "Ospina", value: "Ospina"},
            {label: "Pasto", value: "Pasto"},
            {label: "Policarpa", value: "Policarpa"},
            {label: "Potosí", value: "Potosí"},
            {label: "Providencia", value: "Providencia"},
            {label: "Puerres", value: "Puerres"},
            {label: "Pupiales", value: "Pupiales"},
            {label: "Ricaurte", value: "Ricaurte"},
            {label: "Roberto Payán", value: "Roberto Payán"},
            {label: "Samaniego", value: "Samaniego"},
            {label: "San Andrés de Tumaco", value: "San Andrés de Tumaco"},
            {label: "San Bernardo", value: "San Bernardo"},
            {label: "San Lorenzo", value: "San Lorenzo"},
            {label: "San Pablo", value: "San Pablo"},
            {label: "San Pedro de Cartago", value: "San Pedro de Cartago"},
            {label: "Sandoná", value: "Sandoná"},
            {label: "Santa Bárbara", value: "Santa Bárbara"},
            {label: "Santacruz", value: "Santacruz"},
            {label: "Sapuyes", value: "Sapuyes"},
            {label: "Taminango", value: "Taminango"},
            {label: "Tangua", value: "Tangua"},
            {label: "Túquerres", value: "Túquerres"},
            {label: "Yacuanquer", value: "Yacuanquer"}
        ]
    },
    "Norte de Santander" : {
        value: "Norte de Santander",
        cities: [
            {label: "Ábrego", value: "Ábrego"},
            {label: "Arboledas", value: "Arboledas"},
            {label: "Bochalema", value: "Bochalema"},
            {label: "Bucarasica", value: "Bucarasica"},
            {label: "Cáchira", value: "Cáchira"},
            {label: "Cácota", value: "Cácota"},
            {label: "Chinácota", value: "Chinácota"},
            {label: "Chitagá", value: "Chitagá"},
            {label: "Convención", value: "Convención"},
            {label: "Cucutilla", value: "Cucutilla"},
            {label: "Durania", value: "Durania"},
            {label: "El Carmen", value: "El Carmen"},
            {label: "El Tarra", value: "El Tarra"},
            {label: "El Zulia", value: "El Zulia"},
            {label: "Gramalote", value: "Gramalote"},
            {label: "Hacarí", value: "Hacarí"},
            {label: "Herrán", value: "Herrán"},
            {label: "La Esperanza", value: "La Esperanza"},
            {label: "La Playa", value: "La Playa"},
            {label: "Labateca", value: "Labateca"},
            {label: "Los Patios", value: "Los Patios"},
            {label: "Lourdes", value: "Lourdes"},
            {label: "Mutiscua", value: "Mutiscua"},
            {label: "Ocaña", value: "Ocaña"},
            {label: "Pamplona", value: "Pamplona"},
            {label: "Pamplonita", value: "Pamplonita"},
            {label: "Puerto Santander", value: "Puerto Santander"},
            {label: "Ragonvalia", value: "Ragonvalia"},
            {label: "Salazar", value: "Salazar"},
            {label: "San Calixto", value: "San Calixto"},
            {label: "San Cayetano", value: "San Cayetano"},
            {label: "San José de Cúcuta", value: "San José de Cúcuta"},
            {label: "Santiago", value: "Santiago"},
            {label: "Sardinata", value: "Sardinata"},
            {label: "Silos", value: "Silos"},
            {label: "Teorama", value: "Teorama"},
            {label: "Tibú", value: "Tibú"},
            {label: "Toledo", value: "Toledo"},
            {label: "Villa Caro", value: "Villa Caro"},
            {label: "Villa del Rosario", value: "Villa del Rosario"}
        ]
    },
    "Putumayo" : {
        value: "Putumayo",
        cities: [
            {label: "Colón", value: "Colón"},
            {label: "Mocoa", value: "Mocoa"},
            {label: "Orito", value: "Orito"},
            {label: "Puerto Asís", value: "Puerto Asís"},
            {label: "Puerto Caicedo", value: "Puerto Caicedo"},
            {label: "Puerto Guzmán", value: "Puerto Guzmán"},
            {label: "Puerto Leguízamo", value: "Puerto Leguízamo"},
            {label: "San Francisco", value: "San Francisco"},
            {label: "San Miguel", value: "San Miguel"},
            {label: "Santiago", value: "Santiago"},
            {label: "Sibundoy", value: "Sibundoy"},
            {label: "Valle del Guamuez", value: "Valle del Guamuez"},
            {label: "Villagarzón", value: "Villagarzón"}
        ]
    },
    "Quindío" : {
        value: "Quindío",
        cities: [
            {label: "Armenia", value: "Armenia"},
            {label: "Buenavista", value: "Buenavista"},
            {label: "Calarcá", value: "Calarcá"},
            {label: "Circasia", value: "Circasia"},
            {label: "Córdoba", value: "Córdoba"},
            {label: "Filandia", value: "Filandia"},
            {label: "Génova", value: "Génova"},
            {label: "La Tebaida", value: "La Tebaida"},
            {label: "Montenegro", value: "Montenegro"},
            {label: "Pijao", value: "Pijao"},
            {label: "Quimbaya", value: "Quimbaya"},
            {label: "Salento", value: "Salento"}
        ]
    },
    "Risaralda" : {
        value: "Risaralda",
        cities: [
            {label:"Apía", value: "Apía"},
            {label:"Balboa", value: "Balboa"},
            {label:"Belén de Umbría", value: "Belén de Umbría"},
            {label:"Dosquebradas", value: "Dosquebradas"},
            {label:"Guática", value: "Guática"},
            {label:"La Celia", value: "La Celia"},
            {label:"La Virginia", value: "La Virginia"},
            {label:"Marsella", value: "Marsella"},
            {label:"Mistrató", value: "Mistrató"},
            {label:"Pereira", value: "Pereira"},
            {label:"Pueblo Rico", value: "Pueblo Rico"},
            {label:"Quinchía", value: "Quinchía"},
            {label:"Santa Rosa de Cabal", value: "Santa Rosa de Cabal"},
            {label:"Santuario", value: "Santuario"}
        ]
    },
    "Santander" : {
        value: "Santander",
        cities: [
            {label: "Aguada", value: "Aguada"},
            {label: "Albania", value: "Albania"},
            {label: "Aratoca", value: "Aratoca"},
            {label: "Barbosa", value: "Barbosa"},
            {label: "Barichara", value: "Barichara"},
            {label: "Barrancabermeja", value: "Barrancabermeja"},
            {label: "Betulia", value: "Betulia"},
            {label: "Bolívar", value: "Bolívar"},
            {label: "Bucaramanga", value: "Bucaramanga"},
            {label: "Cabrera", value: "Cabrera"},
            {label: "California", value: "California"},
            {label: "Capitanejo", value: "Capitanejo"},
            {label: "Carcasí", value: "Carcasí"},
            {label: "Cepitá", value: "Cepitá"},
            {label: "Cerrito", value: "Cerrito"},
            {label: "Charalá", value: "Charalá"},
            {label: "Charta", value: "Charta"},
            {label: "Chima", value: "Chima"},
            {label: "Chipatá", value: "Chipatá"},
            {label: "Cimitarra", value: "Cimitarra"},
            {label: "Concepción", value: "Concepción"},
            {label: "Confines", value: "Confines"},
            {label: "Contratación", value: "Contratación"},
            {label: "Coromoro", value: "Coromoro"},
            {label: "Curití", value: "Curití"},
            {label: "El Carmen de Chucuri", value: "El Carmen de Chucuri"},
            {label: "El Guacamayo", value: "El Guacamayo"},
            {label: "El Peñón", value: "El Peñón"},
            {label: "El Playón", value: "El Playón"},
            {label: "Encino", value: "Encino"},
            {label: "Enciso", value: "Enciso"},
            {label: "Florián", value: "Florián"},
            {label: "Floridablanca", value: "Floridablanca"},
            {label: "Galán", value: "Galán"},
            {label: "Gámbita", value: "Gámbita"},
            {label: "Girón", value: "Girón"},
            {label: "Guaca", value: "Guaca"},
            {label: "Guadalupe", value: "Guadalupe"},
            {label: "Guapotá", value: "Guapotá"},
            {label: "Guavatá", value: "Guavatá"},
            {label: "Güepsa", value: "Güepsa"},
            {label: "Hato", value: "Hato"},
            {label: "Jesús María", value: "Jesús María"},
            {label: "Jordán", value: "Jordán"},
            {label: "La Belleza", value: "La Belleza"},
            {label: "La Paz", value: "La Paz"},
            {label: "Landázuri", value: "Landázuri"},
            {label: "Lebrija", value: "Lebrija"},
            {label: "Los Santos", value: "Los Santos"},
            {label: "Macaravita", value: "Macaravita"},
            {label: "Málaga", value: "Málaga"},
            {label: "Matanza", value: "Matanza"},
            {label: "Mogotes", value: "Mogotes"},
            {label: "Molagavita", value: "Molagavita"},
            {label: "Ocamonte", value: "Ocamonte"},
            {label: "Oiba", value: "Oiba"},
            {label: "Onzaga", value: "Onzaga"},
            {label: "Palmar", value: "Palmar"},
            {label: "Palmas del Socorro", value: "Palmas del Socorro"},
            {label: "Páramo", value: "Páramo"},
            {label: "Piedecuesta", value: "Piedecuesta"},
            {label: "Pinchote", value: "Pinchote"},
            {label: "Puente Nacional", value: "Puente Nacional"},
            {label: "Puerto Parra", value: "Puerto Parra"},
            {label: "Puerto Wilches", value: "Puerto Wilches"},
            {label: "Rionegro", value: "Rionegro"},
            {label: "Sabana de Torres", value: "Sabana de Torres"},
            {label: "San Andrés", value: "San Andrés"},
            {label: "San Benito", value: "San Benito"},
            {label: "San Gil", value: "San Gil"},
            {label: "San Joaquín", value: "San Joaquín"},
            {label: "San José de Miranda", value: "San José de Miranda"},
            {label: "San Miguel", value: "San Miguel"},
            {label: "San Vicente de Chucurí", value: "San Vicente de Chucurí"},
            {label: "Santa Bárbara", value: "Santa Bárbara"},
            {label: "Santa Helena del Opón", value: "Santa Helena del Opón"},
            {label: "Simacota", value: "Simacota"},
            {label: "Socorro", value: "Socorro"},
            {label: "Suaita", value: "Suaita"},
            {label: "Sucre", value: "Sucre"},
            {label: "Suratá", value: "Suratá"},
            {label: "Tona", value: "Tona"},
            {label: "Valle de San José", value: "Valle de San José"},
            {label: "Vélez", value: "Vélez"},
            {label: "Vetas", value: "Vetas"},
            {label: "Villanueva", value: "Villanueva"},
            {label: "Zapatoca", value: "Zapatoca"}
        ]
    },
    "Sucre" : {
        value: "Sucre",
        cities: [
            {label: "Buenavista", value: "Buenavista"},
            {label: "Caimito", value: "Caimito"},
            {label: "Chalán", value: "Chalán"},
            {label: "Colosó", value: "Colosó"},
            {label: "Corozal", value: "Corozal"},
            {label: "Coveñas", value: "Coveñas"},
            {label: "El Roble", value: "El Roble"},
            {label: "Galeras", value: "Galeras"},
            {label: "Guaranda", value: "Guaranda"},
            {label: "La Unión", value: "La Unión"},
            {label: "Los Palmitos", value: "Los Palmitos"},
            {label: "Majagual", value: "Majagual"},
            {label: "Morroa", value: "Morroa"},
            {label: "Ovejas", value: "Ovejas"},
            {label: "Palmito", value: "Palmito"},
            {label: "Sampués", value: "Sampués"},
            {label: "San Benito Abad", value: "San Benito Abad"},
            {label: "San José de Toluviejo", value: "San José de Toluviejo"},
            {label: "San Juan de Betulia", value: "San Juan de Betulia"},
            {label: "San Luis de Sincé", value: "San Luis de Sincé"},
            {label: "San Marcos", value: "San Marcos"},
            {label: "San Onofre", value: "San Onofre"},
            {label: "San Pedro", value: "San Pedro"},
            {label: "Santiago de Tolú", value: "Santiago de Tolú"},
            {label: "Sincelejo", value: "Sincelejo"},
            {label: "Sucre", value: "Sucre"}
        ]
    },
    "Tolima" : {
        value: "Tolima",
        cities: [
            {label:"Alpujarra", value: "Alpujarra"},
            {label:"Alvarado", value: "Alvarado"},
            {label:"Ambalema", value: "Ambalema"},
            {label:"Anzoátegui", value: "Anzoátegui"},
            {label:"Armero", value: "Armero"},
            {label:"Ataco", value: "Ataco"},
            {label:"Cajamarca", value: "Cajamarca"},
            {label:"Carmen de Apicalá", value: "Carmen de Apicalá"},
            {label:"Casabianca", value: "Casabianca"},
            {label:"Chaparral", value: "Chaparral"},
            {label:"Coello", value: "Coello"},
            {label:"Coyaima", value: "Coyaima"},
            {label:"Cunday", value: "Cunday"},
            {label:"Dolores", value: "Dolores"},
            {label:"Espinal", value: "Espinal"},
            {label:"Falan", value: "Falan"},
            {label:"Flandes", value: "Flandes"},
            {label:"Fresno", value: "Fresno"},
            {label:"Guamo", value: "Guamo"},
            {label:"Herveo", value: "Herveo"},
            {label:"Honda", value: "Honda"},
            {label:"Ibagué", value: "Ibagué"},
            {label:"Icononzo", value: "Icononzo"},
            {label:"Lérida", value: "Lérida"},
            {label:"Líbano", value: "Líbano"},
            {label:"Melgar", value: "Melgar"},
            {label:"Murillo", value: "Murillo"},
            {label:"Natagaima", value: "Natagaima"},
            {label:"Ortega", value: "Ortega"},
            {label:"Palocabildo", value: "Palocabildo"},
            {label:"Piedras", value: "Piedras"},
            {label:"Planadas", value: "Planadas"},
            {label:"Prado", value: "Prado"},
            {label:"Purificación", value: "Purificación"},
            {label:"Rioblanco", value: "Rioblanco"},
            {label:"Roncesvalles", value: "Roncesvalles"},
            {label:"Rovira", value: "Rovira"},
            {label:"Saldaña", value: "Saldaña"},
            {label:"San Antonio", value: "San Antonio"},
            {label:"San Luis", value: "San Luis"},
            {label:"San Sebastián de Mariquita", value: "San Sebastián de Mariquita"},
            {label:"Santa Isabel", value: "Santa Isabel"},
            {label:"Suárez", value: "Suárez"},
            {label:"Valle de San Juan", value: "Valle de San Juan"},
            {label:"Venadillo", value: "Venadillo"},
            {label:"Villahermosa", value: "Villahermosa"},
            {label:"Villarrica", value: "Villarrica"}
        ]
    },
    "Valle del Cauca" : {
        value: "Valle del Cauca",
        cities: [
            {label: "Alcalá", value: "Alcalá"},
            {label: "Andalucía", value: "Andalucía"},
            {label: "Ansermanuevo", value: "Ansermanuevo"},
            {label: "Argelia", value: "Argelia"},
            {label: "Bolívar", value: "Bolívar"},
            {label: "Buenaventura", value: "Buenaventura"},
            {label: "Bugalagrande", value: "Bugalagrande"},
            {label: "Caicedonia", value: "Caicedonia"},
            {label: "Cali", value: "Cali"},
            {label: "Calima", value: "Calima"},
            {label: "Candelaria", value: "Candelaria"},
            {label: "Cartago", value: "Cartago"},
            {label: "Dagua", value: "Dagua"},
            {label: "El Águila", value: "El Águila"},
            {label: "El Cairo", value: "El Cairo"},
            {label: "El Cerrito", value: "El Cerrito"},
            {label: "El Dovio", value: "El Dovio"},
            {label: "Florida", value: "Florida"},
            {label: "Ginebra", value: "Ginebra"},
            {label: "Guacarí", value: "Guacarí"},
            {label: "Guadalajara de Buga", value: "Guadalajara de Buga"},
            {label: "Jamundí", value: "Jamundí"},
            {label: "La Cumbre", value: "La Cumbre"},
            {label: "La Unión", value: "La Unión"},
            {label: "La Victoria", value: "La Victoria"},
            {label: "Obando", value: "Obando"},
            {label: "Palmira", value: "Palmira"},
            {label: "Pradera", value: "Pradera"},
            {label: "Restrepo", value: "Restrepo"},
            {label: "Riofrío", value: "Riofrío"},
            {label: "Roldanillo", value: "Roldanillo"},
            {label: "San Pedro", value: "San Pedro"},
            {label: "Sevilla", value: "Sevilla"},
            {label: "Toro", value: "Toro"},
            {label: "Trujillo", value: "Trujillo"},
            {label: "Tuluá", value: "Tuluá"},
            {label: "Ulloa", value: "Ulloa"},
            {label: "Versalles", value: "Versalles"},
            {label: "Vijes", value: "Vijes"},
            {label: "Yotoco", value: "Yotoco"},
            {label: "Yumbo", value: "Yumbo"},
            {label: "Zarzal", value: "Zarzal"}
        ]
    },
    "Vaupés" : {
        value: "Vaupés",
        cities: [
            {label: "Carurú", value: "Carurú"},
            {label: "Mitú", value: "Mitú"},
            {label: "Pacoa", value: "Pacoa"},
            {label: "Papunahua", value: "Papunahua"},
            {label: "Taraira", value: "Taraira"},
            {label: "Yavaraté", value: "Yavaraté"}
        ]
    },
    "Vichada" : {
        value: "Vichada",
        cities: [
            {label: "Cumaribo", value: "Cumaribo"},
            {label: "La Primavera", value: "La Primavera"},
            {label: "Puerto Carreño", value: "Puerto Carreño"},
            {label: "Santa Rosalía", value: "Santa Rosalía"}
        ]
    },
}

export default departmentsAndCities;